import components.animations.ErrorMessage
import components.container
import components.error.StandardError
import components.icons.dollarIcon
import components.tao.api.getRewards
import components.tao.api.redeemRewards
import functions.minus
import functions.toCents
import functions.toMoney
import imported.braintree.Values
import imported.numberformat.Values
import imported.numberformat.numberFormat
import kotlinx.css.*
import kotlinx.css.properties.boxShadow
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import styled.*
import styles.Toggle


external interface TaoProps : Props {
	var subtotal: String?
	var discount: String?
	var show: Boolean?
}


@JsModule("./images/tao/img.png")
@JsNonModule
external val taoRewardsLogo: dynamic

val taoDiscount = fc<TaoProps>("Tao Discount") { props ->
	var error by useState(false)
	var rewards by useState("0.00")
	var active by useState(false)
	var applied by useState("0.00")
	var alreadyApplied = applied !== "0.00"
	var applying by useState(false)
	var values by useState(Values().apply {
		value = "0.00"
		formattedValue = "0.00"
		floatValue = 0.0f
	})
	val valueInRange = values.value.lowerThan(rewards, props.subtotal)
	useEffect(props.subtotal,props.show) {
		console.log("gettingRewards")
		getRewards {
			rewards = minus(it.amount, it.used)
			applied = it.used ?: "0.00"
			if (applied != "0.00") {
				values = Values().apply {
					value = applied
					formattedValue = applied
					floatValue = toCents(applied) / 100.0f
				}
			}
		}
	}

	div("section less no-top") {
		container {
			h3("no-top no-bottom") {
				+"Would you like to Redeem Rewards?"
			}
			styledDiv {
				css {
					classes.add("section")
					classes.add("less")
					classes.add("no-bottom")
					classes.add("no-top")

				}
				inlineStyles {
					display = if (error) Display.block else Display.none
				}

				div("message error") {
					ErrorMessage {
						attrs {
							icon = StandardError
							title = "Error with rewards. "
							text = "Try again"
						}
					}
				}


			}

			styledDiv {
				css {
					+TaoBlock.wrapper
				}
				styledDiv {
					css {
						classes += "top"
					}
					inlineStyles { display = Display.flex }
					styledSpan {
						css {
							+TaoRewards.wrapper
						}
					}
					p("no-top-bottom") {
						styledSpan {

							inlineStyles { display = Display.block }
							+"""Rewards Dollars"""
						}
						span("rewards-amount") {
							+"\$$rewards"
						}
						+""" available"""
					}
					styledA {
						css {
							+Toggle.wrapper
							if (active) {
								+Toggle.active
							}

						}

						attrs.href = "#"
						attrs.id = "rewards-toggle"
						attrs.onClickFunction = {
							it.preventDefault()
							active = !active
						}
						styledSpan {
							css {
								+Toggle.toggle
								if (active) {
									+Toggle.toggleActive
								}
							}
						}
					}

				}
				if (active) {
					div("section less no-bottom") {
						div("section less no-top") {
							div("row no-top clearfix") {
								div("input-wrap tip") {
									span("before-input") {
										dollarIcon {}
									}
									numberFormat {
										attrs {
											className = "form-control"
											id = "reward-tip"
											placeholder = "Enter Reward Dollars to Redeem"
											thousandSeparator = true
											allowNegative = false
											if (alreadyApplied) value = applied
											decimalScale = 2
											fixedDecimalScale = true
											disabled = alreadyApplied
											onValueChange = {
												if (it.floatValue < 1.0e14) {
													values = it
												}
											}
										}
									}
									span("btn-right") {
										styledButton {
											css {
												classes += "btn"
												classes += "no-animation"
												classes += if (!applying and canBeApplied(alreadyApplied, valueInRange))
													"white"
												else if (alreadyApplied) {
													"green disabled"
												} else {
													"disabled"
												}

											}
											attrs.onClickFunction = {
												it.preventDefault()
												applying = true
												redeemRewards(values.value) {
													applying = false
													if (it.status == "OK") {
														console.log("Success")
														alreadyApplied = true
														applied = values.value
														rewards = minus(rewards,applied)
														console.log("Values set")
													} else {
														error = true
													}
												}
											}
											+(if (applying) "Applying" else (if (alreadyApplied) "Applied " else "Apply "))
										}
									}
								}

							}
						}
						div("") {
							styledP {
								css {
									classes += "small text-grey no-top-bottom"
								}

								+"""Enter the amount of Rewards Dollars you would like to redeem. Redemption cannot exceed the subtotal amount ($${props.subtotal}). Your selection is final."""
							}
						}

					}
				}
			}
		}

	}
}

fun String.lowerThan(rewards: String, subtotal: String?): Boolean {
	val cents = toCents(this)
	return (cents < toCents(rewards)) and
			(cents < toCents(subtotal ?: "0.00"))
}

private fun canBeApplied(alreadyApplied: Boolean, valueInRange: Boolean): Boolean {
	return !alreadyApplied and valueInRange
}


object TaoBlock : StyleSheet("TaoBlock", isStatic = true) {
	val wrapper by css {
		display = Display.block
		border = "1px solid #29292b"
		backgroundColor = Color("#1a1a1c")
		margin = "5px 0"
		padding = "15px 15px 15px 15px"

		boxShadow(
			color = Color("rgba(0,0,0,0.09)"),
			offsetX = LinearDimension.none,
			offsetY = LinearDimension("1px"),
			blurRadius = LinearDimension("6px"),
			spreadRadius = LinearDimension("1px")
		)


	}
}

object TaoRewards : StyleSheet("TaoRewardsLogo", isStatic = true) {
	val wrapper by css {

		display = Display.inlineBlock
		background = "50% 50% no-repeat #f0f0f0"
		backgroundSize = "cover"
		position = Position.relative;
		width = LinearDimension("42px")
		height = LinearDimension("42px")
		overflow = Overflow.hidden
		flexShrink = 0.0
		marginRight = LinearDimension("12px")
		backgroundImage = Image("url($taoRewardsLogo)")
	}
}
