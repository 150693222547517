import api.environment
import api.hostPath
import api.traceRate
import api.version
import imported.sentry.BrowserTracing
import imported.sentry.BrowserTracingOptionsPartial
import imported.sentry.init
import kotlinx.browser.document
import kotlinx.browser.window
import react.StrictMode
import react.dom.render
import react.router.dom.BrowserRouter
import kotlin.js.json


@Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
fun main() {
	window.onload = {

		init(
			json(
				"dsn" to "https://fd688b95873340f9b829d9c85a499c49@o1001471.ingest.sentry.io/5961105",
				"tracesSampleRate" to traceRate,
				"integrations" to arrayOf(
					BrowserTracing(
						json(
							"tracingOrigins" to arrayOf<String>(hostPath),

							"idleTimeout" to 60000
						) as BrowserTracingOptionsPartial
					)
				),
				"environment" to environment,
				"release" to version
			)
		)

		render(document.getElementById("root")!!) {
			StrictMode{
			BrowserRouter {
				app {}
			}}
//
		}
	}
}

