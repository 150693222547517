package pages

import api.addPaymentFunction
import api.attachBrainTreeFields
import apple.applyApplePay
import components.*
import components.apple.applePayButton
import components.google.googlePayButton
import csstype.Display
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import mainScope
import react.*
import react.css.css
import react.dom.aria.AriaRole
import react.dom.aria.ariaSelected
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.NavigateFunction
import react.router.useNavigate

external interface AddPaymentProps : Props {
	var navigate: (NavigateFunction) -> Unit
}

val addPayment = FC<AddPaymentProps> { props ->
	val history = useNavigate()
	val googleRef = useRef(null)
	val (isLoading, setLoading) = useState(false)
	val (showError, setShowError) = useState(false)
	var googleBehavior: (() -> dynamic)? = null
	useEffectOnce {
		mainScope.launch {
			attachBrainTreeFields().await()
//			googleBehavior = prepareGooglePay(
//				null, { mainScope.async { } },
//				{ setLoading(it) }) {
//				props.navigate(history)
//			}.await()
		}
	}
	layout {
		seo {
			titleTemplate = "Complete Your Account - %s"
		}
		div {
			className = "section no-top-bottom"
			location {
			}
			div {
				className = "section no-bottom"
				container {
					h1 {
						className = "no-top-bottom"
						+"Connect a Payment."
					}
				}
			}
			div {
				className = "section less"
				container {

					div {
						className = "section less no-top"

						css {

							display = Display.none
						}
						container {
							div {
								className = "message error"
								div {
									className = "message-wrap"
									span {
										className = "icon"
										svg {
											className = "svg-icon"
											viewBox = "0 0 24 24"
											path {
												d =
													"M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z"

												fill = "#ec0b19"
											}

										}
									}
								}
							}
						}
					}

					div {
						className = "row clearfix"
						applePayButton {
							appleBehavior =
								applyApplePay({ }, null, { _, _ -> mainScope.async { } }, { setLoading(it) }) {
									props.navigate(history)
								}

						}
						div {
							className = "column"
							div {
								className = "padding"
								googlePayButton {
									this.setLoading = { setLoading(it) }
									this.navigate = props.navigate
								}
//								a {
//									className = "btn brand white"
//
//									onClick = {
//										it.preventDefault()
//										googleBehavior?.invoke()
//									}
//
//									googlePayIcon {}

							}
						}
					}
				}
			}
		}
		div {
			className = "section less no-top"
			container {
				div {
					className = "divider text-center"
					span {
						+"Or pay with card"
					}
				}
			}
		}
		form {
			div {
				className = "section input-group three short no-top"
				container {
					label {
						className = "no-top"
						htmlFor = "card-information"
						+"Card Information"
					}
					div {
						className = "row clearfix"
						div {
							className = "column full"
							div {
								className = "form-control frame"

								placeholder = "Card Number"
								id = "card-number"
								role = AriaRole.option
								ariaSelected = true
								tabIndex = 0

							}
						}
						div {
							className = "column"
							div {
								className = "form-control frame"
								placeholder = "Expires"
								id = "card-expiry"
								role = AriaRole.option
								ariaSelected = true
								tabIndex = 0

							}
						}
						div {
							className = "column"
							div {
								className = "form-control frame"
								placeholder = "CVV"
								id = "card-cvv"
								role = AriaRole.option
								ariaSelected = true
								tabIndex = 0

							}
						}
					}
				}
			}
			div {
				className = "section no-top-bottom"
				container {
					div {
						className = "row clearfix"
						div {
							className = "column full"
							div {
								className = "form-control frame"
								placeholder = "Zip Code"
								id = "card-zip"
								role = AriaRole.option
								ariaSelected = true
								tabIndex = 0

							}
						}
					}
					div {
						className = "error-message"
						css {

							display = if (showError) Display.block else Display.none
						}
						p {
							className = "small text-red no-top-bottom"
							+"Your card was declined. Please try a different card."
						}
					}
				}
			}
			div {
				className = "section less no-bottom"
				container {
					button {
						className = "btn red ${if (isLoading) "disabled" else ""}"

						onClick = {
							addPaymentFunction(it.nativeEvent, {

								setShowError(true)
								setLoading(false)
							}) {
								props.navigate(history)
							}
						}

						+if (isLoading) "Please Wait..." else "Connect"
					}
				}
			}
		}
		div {
			className = "section no-top-bottom"
			container {
				div {
					className = "link-box text-center no-bottom"
					p {
						className = "small text-grey no-top-bottom"
						+"By connecting a payment, you agree to the Rooam's "
						a {
							href = "https://rooam.co/legal"
							target = AnchorTarget._blank
							className = "text-link"
							rel = "noreferrer"
							+"Terms & Conditions"
						}
						+" and "
						a {
							href = "https://rooam.co/policy"
							target = AnchorTarget._blank
							className = "text-link"
							rel = "noreferrer"
							+"Privacy Policy"
						}
					}
				}
			}
		}
		help { isLess = false }
		rooamFooter { }
	}
}

