package api

import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.features.*
import io.ktor.client.features.json.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.util.*
import kotlinx.browser.localStorage
import org.w3c.dom.get


val client = HttpClient(Js) {
	this.engine {
		this.threadsCount = 1
	}
	install(JsonFeature)
	this.defaultRequest {
		url {
			this.protocol = URLProtocol.createOrDefault(hostProtocol)
			port = hostPort
			host = hostPath

		}

		this.attributes.put(AttributeKey("credentials"), "include")

	}
}

object SessionConstants {
	const val rooamSessionHeader = "X-ROOAM-Session"
	const val rooamSessionStaticHeader = "X-ROOAM-Session-Static"
}

fun HttpRequestBuilder.addSessionHeader() {
	header(SessionConstants.rooamSessionHeader, localStorage[SessionConstants.rooamSessionHeader] ?: "INVALID")
}

fun HttpRequestBuilder.addSessionStaticHeader() {
	header(SessionConstants.rooamSessionStaticHeader, localStorage[SessionConstants.rooamSessionHeader] ?: "INVALID")
}

enum class MessageSendState {
	NONE,
	SUCCESS,
	ERROR
}
