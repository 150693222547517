package components.item

import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span


val ItemSmall = FC<ItemProps>("itemSmall") { props ->
	div {
		className = "padding"
		span {
			className = "colored-dot"
		}
		div {
			className = "row clearfix"
			div {
				className = "column three-four"
				p {
					className = "font-weight-500 text-white no-top-bottom"
					id = "item-count"
					+"${props.quantity} x ${props.itemName}"
				}
			}
			div {
				className = "column one-four text-right"
				p {
					className = "font-weight-500 text-white no-top-bottom"
					+"\$${props.itemTotal}"
				}
			}
		}

	}

}


