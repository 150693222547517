package api

import io.ktor.client.call.*
import io.ktor.http.*
import kotlinx.browser.localStorage
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import pages.Tips
import pages.getSingleFeed


suspend fun getFeed(
): Feed? {
	val feed = getSingleFeed()
	if (feed.status == HttpStatusCode.Unauthorized) {
		return null
	} else {
		return feed.receive()
	}
}

fun setTips(tips: Tips) {
	localStorage.setItem("TIPS", Json.encodeToString(tips))
}



