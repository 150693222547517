package api

val hostPath = js("HOST")
val hostProtocol = js("HOSTPROTOCOL")
val hostPort = js("PORT")


val googlePayEnvironment = js("GOOGLE_PAY_ENVIRONMENT")
val googlePayMerchantID = js("GOOGLE_PAY_MERCHANT_ID")
val googleAnalytics : String= js("GOOGLE_ANALYTICS")
val version : String= js("VERSION")
val environment : String= js("ENVIRONMENT")
val traceRate : Double= js("TRACE")
