package components.summary

import api.HistoryData
import components.Receipt
import components.animations.ErrorMessage
import components.animations.RotatingLoader
import components.container
import components.error.ConnectionError
import components.error.StandardError
import functions.sum
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.useState

@JsModule("./styles/summary.css")
@JsNonModule
external val summaryStyle: dynamic

enum class SummaryState {
	CONNECTION_ERROR, ERROR, LOADING, SUCCESS

}

data class SummaryData(
	val summaryState: SummaryState,
	val historyData: HistoryData? = null
)

external interface SummaryProps : Props {
	var summaryData: SummaryData

}


val Summary = FC<SummaryProps>("Summary") { props ->
	var isSummaryActive: Boolean by useState(false)

	div() {
		className = "section no-top-bottom"
		when (props.summaryData.summaryState) {
			SummaryState.CONNECTION_ERROR -> {
				div() {
					className = "section less summary-btn"
					container {
						ErrorMessage {
							title = "Connection Error."
							text = "Please try refreshing page or logging out and back in."
							icon = ConnectionError
						}
					}
				}
			}
			SummaryState.ERROR -> {
				div() {
					className = "section less summary-btn"
					container {
						ErrorMessage {

							title = "Error Generating Tab Details."
							text = "Last Tab Details cannot be generated."
							icon = StandardError

						}
					}
				}
			}
			SummaryState.LOADING -> {
				div() {
					className = "section less summary-btn"
					container {
						RotatingLoader {
							text = "Generating Tab Details..."
						}
					}
				}
			}
			SummaryState.SUCCESS -> {
				a {
					className = "section less summary-btn"
					if (isSummaryActive) className += " active"

					onClick = {
						it.preventDefault()
						isSummaryActive = !isSummaryActive
					}
					tabIndex = 0
					container {
						div() {
							className = "row clearfix"
							div() {
								className = "column"
								p() {
									className = "text-white font-weight-600 no-top-bottom"
									+"Tab Details"

									div() {
										className = "expand-btn"
										span() {
											className = "icon"
											svg() {
												className = "svg-icon"
												viewBox = "0 0 24 24"
												path {
													d =
														"M24 12c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12zm-18.005-1.568l1.415-1.414 4.59 4.574 4.579-4.574 1.416 1.414-5.995 5.988-6.005-5.988z"
													fill = "#bebebe"

												}
											}
										}
									}
								}
							}

							div() {
								className = "column text-right"
								p() {
									className = "text-white font-weight-600 no-top-bottom"
									+"\$${props.summaryData.historyData?.receipt?.total}"
								}
							}
						}
					}
				}
				div {
					className = ""
					className += "section"
					className += " summary-bg"
					className += " summary-info"
					className += " no-top-bottom"
					if (isSummaryActive) {
						className += " active"
					}

					SummaryItems {
						items = props.summaryData.historyData!!.items
					}
					div() {
						className = "section less"
						Receipt {

							val receipt = props.summaryData.historyData!!.receipt
							this.fees = receipt.fee ?: "0.00"
							this.tax = receipt.tax ?: "0.00"
							this.tip = receipt.tip
							this.discount = receipt.discounts ?: "0.00"
							this.subtotal = receipt.subTotal
							this.serviceCharges = receipt.serviceCharges ?: "0.00"
							this.credit = receipt.creditApplied
							this.taxesFees = sum(sum(receipt.tax, receipt.fee), receipt.serviceCharges)

						}
					}
					div() {
						className = "section no-top"
						container {
							div() {
								className = "row clearfix"
								div() {
									className = "column"
									p() {
										className = "medium text-white font-weight-500 no-top-bottom"
										+"Total:"
									}
								}
								div() {
									className = "column text-right"
									p() {
										className = "medium text-white font-weight-500 no-top-bottom"
										+props.summaryData.historyData!!.receipt.total
									}
								}
							}
						}
					}

				}

			}

		}
	}

}





