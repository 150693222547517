package components

import csstype.pct
import kotlinext.js.jso
import react.CSSProperties
import react.FC
import react.Props
import react.dom.aria.AriaRole
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div

external interface SingleTipProps : Props {
	var width: Int
	var value: Pair<Int, String>
	var active: Boolean?
	var onTipChange: (Int, String) -> Unit
	var setTipActive: (Int) -> Unit
}

val singleTip = FC<SingleTipProps> { props ->
	div() {
		className = "column"
		style = jso<CSSProperties>().apply {
			width = (props.width / 10.0).pct
			maxWidth = (props.width / 10.0).pct
		}
		div() {
			className = "padding"
			a {
				className =
					(if (props.active == true) "btn tip active" else "btn tip") + if (props.value.first != 0) "" else " no-tip"

					id = "tip-${props.value.first}"
					onClick = {
						it.preventDefault()
						props.setTipActive(props.value.first)
						props.onTipChange(props.value.first, props.value.second)
					}
					tabIndex = 0
					role = AriaRole.button
					//onKeyPress = { props.setTipActive(props.value.first) }


				if (props.value.first == 0) {
					+"No Tip"
				} else {
					+"${props.value.first}%"
					p500 {
						+"\$${props.value.second}"
					}
				}
			}
		}
	}
}
