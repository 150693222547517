package components.item

import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span

external interface NumberBoxProps : Props {
	var quantity: Int
}

var itemNumberBox = FC<NumberBoxProps>("itemQuantity") { props ->
	div {
		className = "number-box"
		div {
			className = "number-box-outter"
		}
		div {
			className = "number-box-inner"
		}
		div {
			className = "number text-center text-white"
			span {
				className = "font-weight-600 item-quantity"
				+"${props.quantity}"
			}
		}
	}
}
