package components

import api.Payment
import api.deletePayment
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.useState

external interface DeletePaymentProps : Props {
	var payment: Payment
	var reload: () -> Unit
	var disable: (Boolean) -> Unit
}

val deletePaymentButton = FC<DeletePaymentProps> { props ->
	val (showModal, setShowModal) = useState(false)
	if (showModal) confirmModal {
		showIcon = false
		text = "Tap 'Confirm' if you wish to delete your payment."
		title = "Delete payment?"
		booleanShowModal = showModal
		confirmActionPressed = {
			it.preventDefault()
			deletePayment(props.payment, props.disable, props.reload)
			setShowModal(false)
		}
		cancelActionPressed = {
			it.preventDefault()
			setShowModal(false)
		}
	}
	a {
		className = "delete-btn"

		onClick = {
			it.preventDefault()
			setShowModal(true)
		}

		span {
			className = "icon"
			svg {
				className = "svg-icon"
				viewBox = "0 0 24 24"
				path {
					d =
						"M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"
					fill = "#737373"


				}
			}
		}
	}
}

