package pages

import api.*
import apple.applyApplePay
import apple.canUseApplePay
import components.*
import components.utils.orbit
import csstype.Display.Companion.block
import csstype.Display.Companion.none
import csstype.px
import functions.sum
import google.prepareGooglePay
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.html.id
import locationBase
import mainScope
import react.*
import react.css.css
import react.dom.*
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.dom.Link
import react.router.useNavigate
import react.router.useParams

var googleBehaviorOverdue: (() -> Any)? = null
var appleBehaviorOverdue: (() -> Unit)? = null

val tabOverdue = FC<Props> {
	val useHistory = useNavigate()
	val useParams = useParams()
	val connectButton = useRef(null)

	val (loading, setLoading) = useState(false)
	val (payments, setPayments) = useState(
		listOf(
			Payment(
				label = "Loading Payments......",
				type = "LOADING",
				default = false,
				id = "NONE",
				image = ""
			)
		)
	)
	val (overdueResponse, setOverdueResponse) = useState<OverdueResponse?>(null)
	val (selectedPayment, setSelectedPayment) = useState<Payment?>(null)
	val (paymentError, setPaymentError) = useState(false)
	val (showApplePayAlert, setShowApplePayAlert) = useState(false)
	useEffectOnce {

		mainScope.launch {
			var stopProcessing = false
			var overdueAmount: String? = null
			getOverdueDetails(setOverdueResponse, { overdueAmount = it }) {
				moveToNext(useHistory, useParams)
				stopProcessing = true

			}.await()
			if (stopProcessing) return@launch
			loadPayments(setPayments).await()
			setSelectedPayment(payments.find { it.default })
			googleBehaviorOverdue = prepareGooglePay(

				overdueAmount,
				{ mainScope.async { } },
				{
					setLoading(it)
					if (!it) {
						setPaymentError(!it)
					}
				}
			) {
				payOverdue(it, {
					setPaymentError(true)
					setLoading(false)
				}) {
					useHistory("${locationBase(useParams)}/overduecomplete")
				}
			}.await()
			appleBehaviorOverdue = applyApplePay(
				{ },
				overdueAmount,
				{ _, _ -> mainScope.async { } },
				{
					setLoading(it)
					if (!it) {
						setPaymentError(!it)
					}
				}) {

				payOverdue(it, {
					setPaymentError(true)
					setLoading(false)
				}) {
					useHistory("${locationBase(useParams)}/overduecomplete")
				}
			}.await()
		}

	}
	layout {
		seo {
			titleTemplate = "Unpaid Tab - %s"
		}
		if (showApplePayAlert) modalAlert {
			this.title = "Apple Pay unavailable."
			this.text =
				"You cannot use Apple Pay with current browser. Please switch to Safari or choose another payment method."
			this.confirmActionPressed = {
				it.preventDefault()
				setShowApplePayAlert(false)
				setLoading(false)
			}
		}
		divPage {
			div() {
				className = "section large text-center"
				container {
					div() {
						className = "orbit-box medium"
						orbit()
						div() {
							className = "icon"
							svg() {
								className = "svg-icon"
								viewBox = "0 0 24 24"
								path {
									d =
										"M12,7c0.6,0,1,0.4,1,1v4c0,0.6-0.4,1-1,1s-1-0.4-1-1V8C11,7.4,11.4,7,12,7z M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10 c5.5,0,10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S16.4,20,12,20z M13,17h-2v-2h2V17z"


									fill = "#ffffff"

								}
							}
						}
					}
					h2() {
						className = "no-top-bottom"
						+"Unpaid Tab."
					}
					p {
						className = "float-middle no-bottom"


						css {
							maxWidth = 480.px
						}
						+"Something went wrong while processing your payment. Before you can Rooam again you must settle the balance from your last tab."
					}
				}
			}
			div() {
				className = "section no-top"
				container {
					h2() {
						className = "no-top-bottom"
						+"Select Payment"
					}
				}
			}
			divPage {
				container {
					paymentList {

						this.payments = payments
						onPaymentSelect = {
							setSelectedPayment(it)
						}
						showDelete = false
						reload = {}

					}
				}
			}
			div() {
				className = "section short no-bottom"
				container {
					Link {

						to = "${locationBase(useParams)}/addpayment"
						className = "btn small"

						+"Add New Payment"
					}
				}
			}
			div() {
				className = "section large no-bottom"
				container {
					h2() {
						className = "no-top-bottom"
						+"Tab Details"
					}
				}
			}
			div() {
				className = "section no-bottom"
				container {
					if (overdueResponse?.overdueTabs == null) {
						ItemsLoading {

						}
					} else {
						orderedItems {
							items = overdueResponse.overdueTabs[0].items.toTypedArray()

						}
					}
				}
			}
			div() {
				className = "section"
				Receipt {
					this.subtotal = overdueResponse?.overdueTabs?.get(0)?.subTotal ?: "0.00"
					this.tip = overdueResponse?.overdueTabs?.get(0)?.tip ?: "0.00"
					this.tax = overdueResponse?.overdueTabs?.get(0)?.tax ?: "0.00"
					this.fees = overdueResponse?.overdueTabs?.get(0)?.baseFare ?: "0.00"
					this.serviceCharges = overdueResponse?.overdueTabs?.get(0)?.serviceCharges
					this.discount = overdueResponse?.overdueTabs?.get(0)?.discounts ?: "0.00"
					this.taxesFees = sum(
						overdueResponse?.overdueTabs?.get(0)?.tax,
						sum(
							overdueResponse?.overdueTabs?.get(0)?.serviceCharges,
							overdueResponse?.overdueTabs?.get(0)?.baseFare
						)
					)

				}
			}
			div() {
				className = "section less bar"
				container {
					div() {
						className = "row clearfix"
						div() {
							className = "column"
							p() {
								className = "text-white font-weight-600 no-top-bottom"
								+"Total:"
							}
						}
						div() {
							className = "column text-right"
							p() {
								className = "text-white font-weight-600 no-top-bottom"
								id = "tab-total"
								+"\$${overdueResponse?.overdueTabs?.get(0)?.total ?: overdueResponse?.amount ?: "0.00"}"
							}
						}
					}
				}
			}
			div {
				className = "section less no-bottom"
				css {
					display = if (paymentError) block else none
				}
				container {
					div() {
						className = "message error"
						div() {
							className = "message-wrap"
							span() {
								className = "icon"
								svg() {
									className = "svg-icon"
									viewBox = "0 0 24 24"
									path {
										d =
											"M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z"
										fill = "#ec0b19"

									}
								}
							}
							p() {
								className = "small text-white no-top-bottom"
								span() {
									className = "font-weight-600"
									+"Issue Closing Tab."
								}
								+" Please try again."
							}
						}
					}
				}
			}
			divPage {
				div() {
					className = "container text-center"
					div() {
						className = "section less no-bottom"
						a() {
							className = "btn red ${if (loading) "disabled" else ""}"
							onClick = {

								it.preventDefault()
								val myPayment = selectedPayment ?: payments.find { it.default } ?: payments[0]
								setLoading(true)
								setPaymentError(false)
								if (myPayment.type == "AP") {
									if (canUseApplePay()) {
										appleBehaviorOverdue?.invoke()
									} else {
										setShowApplePayAlert(true)
									}
								} else

									if (myPayment.type == "GP") {
										googleBehaviorOverdue?.invoke()

									} else {
										updatePayment(myPayment, {
											setPaymentError(true)
											setLoading(false)
										}) {
											payOverdue(null, {
												setPaymentError(true)
												setLoading(false)
											}) {
												useHistory("${locationBase(useParams)}/overduecomplete")
											}
										}

									}
							}

							if (!loading) {
								+"Close Tab: "
								span {
									id = "tab-total"
									+"\$${overdueResponse?.overdueTabs?.get(0)?.total ?: "0.00"}"
								}
							} else {
								+"Closing Tab..."
							}
						}
					}
				}
			}
			div() {
				className = "section no-top-bottom"
				container {
					div() {
						className = "link-box text-center no-bottom"
						p() {
							className = "small text-grey no-top-bottom"
							+"Unpaid tabs are set with a 20% tip."
						}
					}
				}
			}
			help { }
			rooamFooter { }
		}
	}
}

