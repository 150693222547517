package components.animations

import react.ComponentType
import react.Props
import react.dom.div
import react.dom.span
import react.fc
import styled.css
import styled.styledP

external interface ErrorMessageProps : Props {
    var title: String
    var text: String
    var icon: ComponentType<*>
    var isSmall: Boolean?
}

val ErrorMessage = fc<ErrorMessageProps>("Error Message") { props ->
    div("message-wrap") {
        props.icon {
        }
        styledP {
            css {
                classes += "text-white"
                classes += "no-top-bottom"
                if (props.isSmall == true) {
                    classes += "small"
                }
            }
            span("font-weight-600") {
                +props.title
            }
            +props.text
        }
    }
}