package components

import api.Payment
import functions.getImageFromPayment
import locationBase
import react.Props
import react.dom.*
import react.fc
import react.router.dom.Link
import react.router.useParams

external interface ChoosePaymentProps : Props {
    var payment: Payment
}

val choosePayment = fc<ChoosePaymentProps>("choosePayment") { props ->
    val codeProps = useParams()
    div("section no-top-bottom") {
        ul("payments clearfix") {
            li {
                div(classes = "padding") {
                    span(classes = "payment-card-icon") {
                        img(src = getImageFromPayment(props.payment)) {}
                    }
                    p(classes = "medium font-weight-500 text-white no-top-bottom") {
                        +props.payment.label
                    }
                    span("btn-right") {
                        Link {
                            attrs {
                                to = "${locationBase(codeProps)}/select"
                                className = "btn no-animation"
                            }
                            +"Change"
                        }
                    }
                }
            }
        }
    }
}


