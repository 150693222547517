package components

import api.VenueDetails
import api.logout
import csstype.BackgroundImage
import csstype.Display
import imported.googleanalitycs.gtag
import kotlinext.js.jso
import kotlinx.browser.sessionStorage
import kotlinx.coroutines.launch
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import locationBase
import mainScope
import org.w3c.dom.get
import react.CSSProperties
import react.FC
import react.Props
import react.css.css
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.ul
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.useLocation
import react.router.useNavigate
import react.router.useParams
import react.useState
import kotlin.js.json

@JsModule("./styles/location.css")
@JsNonModule
external val locationStyle: dynamic

external interface LocationProps : Props {
	var isExplainer: Boolean?
	var isLong: Boolean?
	var showMenu: Boolean?
	var showBack: Boolean?

}


val location = FC<LocationProps> { props ->
	val codeProps = useParams()
	val history = useNavigate()
	val location = useLocation()


	val get = sessionStorage["VENUE-${codeProps["code"]}"]
	val isPaytronix = sessionStorage.getItem("taoEnabled")
	val venueDetails = if (get != null) {
		Json.decodeFromString(get)
	} else
		VenueDetails("LOADING", "", "", "Loading")

	val (isMenuActive, setMenuActive) = useState(false)


	if (isPaytronix != null) {
		div {}
	} else
		div {
			className = "section header ${if (props.isLong == true) "" else "short"}  no-top-bottom"
			a {
				className = "nav-btn back"
				style= jso<CSSProperties>().apply {
					if (props.showBack == false) {
						display = Display.none
					}
				}

				onClick = {
					it.preventDefault()
					history(-1)
				}

				div {
					className = "icon"
					svg {
						className = "svg-icon"
						viewBox = "0 0 24 24"
						path {

							d = "M20,11H7.8l5.6-5.6L12,4l-8,8l8,8l1.4-1.4L7.8,13H20V11z"
							fill = "#ffffff"
						}
					}
				}

			}
			a {
				className = "nav-btn more no-select" + if (isMenuActive) {
					" active"
				} else ""
				style = jso<CSSProperties>().apply {
					display = if (props.showMenu == false) {
						Display.none
					} else Display.block
				}

				onClick = {
					it.preventDefault()
					setMenuActive(!isMenuActive)
				}

				div {
					className = "icon more"
					svg {
						className = "svg-icon"
						viewBox = "0 0 24 24"
						path {
							d =
								"M6,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S7.1,10,6,10z M18,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S19.1,10,18,10z M12,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S13.1,10,12,10z"
							css {
								fill = "#ffffff"
							}
						}
					}
				}
				div {
					className = "icon close"
					svg {
						className = "svg-icon"
						viewBox = "0 0 24 24"
						path {
							d =
								"M19,6.4L17.6,5L12,10.6L6.4,5L5,6.4l5.6,5.6L5,17.6L6.4,19l5.6-5.6l5.6,5.6l1.4-1.4L13.4,12L19,6.4z"
							css {
								fill = "#ffffff"
							}

						}
					}
				}
			}
			ul {
				className = if (isMenuActive)
					"more-sub-nav active"
				else
					"more-sub-nav"
				li {
					a {
						href = "https://forms.gle/jqzZSNnEBNcmynsz6"
						target = AnchorTarget._blank
						className = "help-wrap"
						rel = "noreferrer"
						onClick = {
							gtag(
								"event", "help_request", json(
									"location" to codeProps["code"],
									"path" to location.pathname
								)
							)
						}
						p {
							className = "medium text-white font-weight-500 no-top-bottom"
							+"Need Help"
						}
					}
				}
				li {
					a {
						href = "${locationBase(codeProps)}/logout"

						onClick = {
							it.preventDefault()
							mainScope.launch {
								logout()
								history("${locationBase(codeProps)}/phone")
								setMenuActive(false)
							}
						}
						p {
							className = "medium text-white font-weight-500 no-top-bottom"
							+"Log Out"
						}
					}
				}
			}
			div {
				className = "location-img-hero"
				style= jso<CSSProperties>().apply {
					backgroundImage = "url(${venueDetails.image})" as BackgroundImage
				}
				div {
					className = "overlay"
				}
			}
			div {
				className = "location-profile-wrap ${if (props.isExplainer == true) "explainer" else ""}"
				div {
					className = "profile-box"
					div {
						className = "profile-outter"
					}
					div {
						className ="profile-inner"
						style= jso<CSSProperties>().apply {
							backgroundImage= "url(${venueDetails.logo})" as BackgroundImage
						}
					}
				}
			}
			if (props.isExplainer != true) {
				div {
					className = "section location-info no-top"
					div {
						className = "container"
						h1 {
							className = "big no-top-bottom"
							+venueDetails.name
						}
						p {
							className = "address medium less-space no-bottom"
							+venueDetails.address
						}
					}
				}
				hr {}
			}
		}
}




