package components

import api.TabItem
import components.item.item
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul

external interface OrderedItemsProps : Props {
	var items: Array<TabItem>
}

@JsNonModule
@JsModule("./styles/ordered-items.css")
external val orderedItemsStyles: dynamic

val orderedItems = FC<OrderedItemsProps> { props ->

	div {
		className="section no-top-bottom"
		ul {
			className = "ordered-items clearfix"
			props.items.forEach {
				li {
					item {

							this.itemName = it.name
							this.itemPrice = it.pricePerUnit
							this.itemTotal = it.price
							this.quantity = it.quantity

					}
				}
			}
		}
	}
}
