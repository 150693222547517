package pages

import components.layout
import components.location
import components.rooamFooter
import components.seo
import components.utils.orbit
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.p
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.useState

external interface HelpPageProps : Props

external fun encodeURIComponent(str: String): String

val helpPage = FC<HelpPageProps> {
	val (phone, setPhone) = useState("")
	val (email, setEmail) = useState("")
	val (text, setText) = useState("")
	layout {
		seo {
			titleTemplate = "Need Help? - %s"
		}
		location {
			showMenu = false
		}
		div() {
			className = "section no-top-bottom"
			div() {
				className = "section large no-bottom"
				div() {
					className = "container text-center"
					div() {
						className = "orbit-box medium"
						orbit()
						div() {
							className = "icon"
							svg() {
								className = "svg-icon"
								path {
									d =
										"M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"


									fill = "#ffffff"


								}
							}
						}
					}
					h1() {
						className="no-top-bottom"
						+"Need help?"
					}
					p() {
						className="no-bottom"
						+"Please fill in the fields below, and describe in as much detail the issue you’re having. We will email you back as soon as possible."
					}
				}
			}
//			form {
//				attrs.id = "form-help"
//				div("section no-bottom") {
//					container {
//						styledDiv {
//							css {
//								classes.add("section")
//								classes.add("no-top")
//
//							}
//							inlineStyles {
//								display = Display.none
//							}
//							div("message success") {
//								div("message-wrap") {
//									successIcon()
//									p(classes = "small text-white no-top-bottom") {
//										span("font-weight-600") {
//											+"Help Sent!"
//										}
//										+" We'll reach back out as soon as possible."
//									}
//								}
//							}
//							div("message error") {
//								div("message-wrap") {
//									span("icon") {
//										svg("svg-icon") {
//											attrs.viewBox = "0 0 24 24"
//											path(
//												d = "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z"
//											) {
//
//												inlineStyles {
//													declarations["fill"] = "#ec0b19"
//												}
//
//											}
//										}
//									}
//									p(classes = "small text-white no-top-bottom") {
//										span("font-weight-600") {
//											+"Send Error."
//										}
//										+" There was an issue sending your help issue. Please try again."
//									}
//								}
//							}
//						}
//						label(classes = "no-top") {
//							attrs["htmlFor"] = "phone-number"
//							+"Phone Number."
//						}
//						div("row clearfix") {
//							div("column full") {
//								child(phoneInput) {
//									attrs {
//										this.onValueChange = {
//											setPhone(it.formattedValue)
//										}
//									}
//								}
//								styledDiv {
//									css {
//										classes.add("error-message")
//
//									}
//									inlineStyles { display = Display.none }
//									p("small text-red no-top-bottom") {
//										+"Please enter a valid phone number."
//									}
//								}
//							}
//						}
//					}
//				}
//				div("section less no-bottom") {
//					container {
//						label("no-top") {
//							attrs["htmlFor"] = "whats-your-issue"
//							+"Email Address"
//						}
//						div("row clearfix") {
//							div("column full") {
//								input(classes = "form-control", type = InputType.email) {
//									attrs {
//										placeholder = "Email Address"
//										id = "email-address"
//										value = email
//										onChangeFunction = { setEmail(it.target.asDynamic().value.toString()) }
//									}
//								}
//							}
//						}
//						styledDiv {
//							css {
//								classes.add("error-message")
//
//							}
//							inlineStyles { display = Display.none }
//							p("small text-red no-top-bottom") {
//								+"Please enter a valid email address."
//							}
//						}
//					}
//				}
//				div("section less no-bottom") {
//					container {
//						label("no-top") {
//							attrs["htmlFor"] = "whats-your-issue"
//							+"What Is Your Issue?"
//						}
//						div("row clearfix") {
//							div("column full") {
//								textarea(classes = "form-control", rows = "4", cols = "50") {
//									attrs.placeholder = "Write Feedback Here"
//									attrs.id = "help-issue"
//									attrs["type"] = "text"
//									attrs.value = text
//									attrs.onChangeFunction = { setText(it.target.asDynamic().value.toString()) }
//								}
//							}
//						}
//						styledDiv {
//							css {
//								classes.add("error-message")
//
//							}
//							inlineStyles { display = Display.none }
//							p("small text-red no-top-bottom") {
//								+"Input can not be empty."
//							}
//						}
//					}
//				}
//				div("section no-top-bottom") {
//					container {
//						div("link-box no-bottom") {
//							button(classes = "btn white") {
//								attrs {
//									onClickFunction = {
//										it.preventDefault()
//										val subject = encodeURIComponent("Issue with Web tab")
//										val body = encodeURIComponent(
//											"""Hello I have an issue with web tab:
//                                               |
//                                               |$text
//                                               |
//                                               |My phone number is: $phone
//                                               |My email is: $email
//                                            """.trimMargin()
//										)
//										window.location.href = "mailto:help@rooam.co?subject=$subject&body=$body"
//									}
//								}
//								+"Send"
//
//							}
//						}
//					}
//				}
//			}
			rooamFooter {}
		}
	}
}

