package pages

import api.Payment
import api.loadPayments
import api.updatePayment
import apple.applyApplePay
import apple.canUseApplePay
import components.*
import components.animations.ErrorMessage
import components.error.StandardError
import csstype.Display
import google.prepareGooglePay
import imported.googleanalitycs.gtag
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import locationBase
import mainScope
import react.*
import react.css.css
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.router.NavigateFunction
import react.router.Params
import react.router.dom.Link
import react.router.useNavigate
import react.router.useParams
import kotlin.js.json

external interface ErrorData : Props {
	var errorMessage: Boolean?
}



external interface ExtraState {
	var state: ErrorData?
}

external interface SelectPaymentProps : Props {
	var navigate: (NavigateFunction, Params, StateSetter<Boolean>, StateSetter<Boolean>) -> Unit
	var compareDefault: (Payment, Payment) -> Boolean
	var showBack: Boolean?
	var error: ErrorData?
	var showDelete: Boolean?
	var errorMessageText: String?
}

var appleBehavior: (() -> Any)? = null
var googleBehavior: (() -> Any)? = null

val selectPayment = FC<SelectPaymentProps> { props ->
	val history = useNavigate()
	val codeProps = useParams()
	val (loading, setLoading) = useState(false)
	val (showApplePayAlert, setShowApplePayAlert) = useState(false)

	val (error, setError) = useState(props.error?.errorMessage ?: false)
	val (payments, setPayments) = useState(
		listOf(
			Payment(
				label = "Loading Payments...",
				type = "LOADING",
				default = false,
				id = "NONE",
				image = ""
			)
		)
	)
	var selectedPayment = payments.find { it.default }
	val defaultPayment = payments.find { it.default }

	useEffectOnce {
		loadPayments(setPayments)
		mainScope.launch {
			googleBehavior = prepareGooglePay(
				null,
				{ mainScope.async { } },
				{
					setLoading(it)
					if (!it) {
						setError(!it)
					}

				}
			) {
				props.navigate(
					history,
					codeProps,
					setError,
					setLoading
				)
			}.await()
			appleBehavior = applyApplePay(
				{ },
				null,
				{ _, _ -> mainScope.async { } },
				{
					setLoading(it)
					if (!it) {
						setError(!it)
					}
				}) {
				props.navigate(
					history, codeProps, setError,
					setLoading
				)
			}.await()
		}

	}

	layout {
		seo { titleTemplate = "Select A Payment - %s" }
		div {
			className = "section no-top-bottom"
			location {
				showBack = props.showBack
			}
		}
		if (showApplePayAlert) modalAlert {
			this.title = "Apple Pay unavailable."
			this.text =
				"You cannot use Apple Pay with current browser. Please switch to Safari or choose another payment method."
			this.confirmActionPressed = {
				it.preventDefault()
				setShowApplePayAlert(false)
				setLoading(false)
			}
		}
		div {
			className = "section less no-bottom"




			css {
				display = if (error) Display.block else Display.none
			}
			container {
				div() {
					className = "message error"
					ErrorMessage {
						icon = StandardError
						title = (props.errorMessageText ?: "Issue connecting payment.")
						text = " Please try again."

					}
				}
			}
		}
		div() {
			className = "section"
			container {
				h1() {
					className = "no-top-bottom"
					+"Select Payment."
				}
			}
		}
		div() {
			className = "section no-top-bottom"
			container {
				paymentList {

					this.payments = payments
					this.onPaymentSelect = {
						selectedPayment = it
					}
					this.showDelete = props.showDelete
					this.reload = { loadPayments(setPayments) }

				}
			}
		}
		div() {
			className = "section short no-bottom"
			container {
				Link {
					to = "${locationBase(codeProps)}/addpayment"
					className = "btn small"

					+"Add New Payment"
				}
			}
		}
		div() {
			className = "section large no-bottom"
			container {
				button {
					className = "btn red ${if (loading) "disabled" else ""}"

					onClick = {
						it.preventDefault()
						gtag("event", "payment_select", json())
						setLoading(true)
						setError(false)
						if (selectedPayment?.type == "GP") {
							googleBehavior?.invoke()
						} else if (selectedPayment?.type == "AP") {
							if (
								canUseApplePay()
							) {
								appleBehavior?.invoke()
							} else {
								setShowApplePayAlert(true)
							}
						} else {

							selectedPayment?.let<Payment, Unit> {
								if (selectedPayment != defaultPayment
								) {
									updatePayment(it, {
										setError(true)
										setLoading(false)
									}, {
										props.navigate(
											history, codeProps, setError,
											setLoading
										)
									})
								} else {
									props.navigate(
										history, codeProps, setError,
										setLoading
									)
								}
							}
						}

					}


					+if (loading) "Please Wait..." else "Connect"
				}
			}
		}
		help { }
		rooamFooter { }
	}
}



