package api

import CannotProcessPaymentException
import imported.braintree.BField
import imported.braintree.BStyles
import imported.braintree.BraintreeFields
import imported.braintree.HostedFieldsOptions
import imported.braintree.hosted.HostedFields
import imported.braintree.hosted.braintreeHostedFields
import io.ktor.client.request.*
import io.ktor.client.statement.*
import kotlinx.browser.window
import kotlinx.coroutines.async
import kotlinx.coroutines.await
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mainScope
import org.w3c.dom.events.Event
import suspendedBrainTreeClient
import kotlin.js.json

var braintreeHostedFieldsIntance: HostedFields? = null


val attachBrainTreeFields = {
    mainScope.async {
        val braintreeClientAwaited = try {
            suspendedBrainTreeClient.await()
        } catch (e: CannotProcessPaymentException) {
            window.alert("Reload page")
        }
        braintreeHostedFieldsIntance = try {
            buildHostedFields(braintreeClientAwaited)
        } catch (e: Exception) {
            console.log(e)
            var error = true
            var fields: HostedFields? = null
            while (error) {
                delay(100)
                try {
                    error = false
                    fields = buildHostedFields(braintreeClientAwaited)
                } catch (newEx: Exception) {
                    console.log(newEx)
                    error = true
                }
            }
            fields
        }


    }
}

private suspend fun buildHostedFields(braintreeClientAwaited: Any) = braintreeHostedFields(
    HostedFieldsOptions().apply {
        client = braintreeClientAwaited
        styles = BStyles().apply {
            input = json(
                "color" to "#ffffff",
                "font-size" to "14px",
                "font-family" to "'Calibre', Helvetica Neue, Helvetica, Arial, sans-serif"
            )
        }
        fields = BraintreeFields().apply {
            cvv = BField().apply {
                container = "#card-cvv"
                placeholder = "CVV"
            }
            expirationDate = BField().apply {
                container = "#card-expiry"
                placeholder = "MM/YYYY"
            }

            number = BField().apply {
                container = "#card-number"
                placeholder = "Card Number"
            }
            postalCode = BField().apply {
                container = "#card-zip"
                placeholder = "Zip Code"
            }
        }
    }
).await()


val addPaymentFunction = { it: Event,
                           errorHandler: () -> Unit,
                           historyChange: () -> Unit
    ->
    it.preventDefault()
    if (braintreeHostedFieldsIntance != null) {
        mainScope.launch {
            try {
                val deviceData = collectDeviceData(
                    suspendedBrainTreeClient.await()
                ).await().deviceData
                val braintreeHostedFieldsInstanceCopy =
                    braintreeHostedFieldsIntance ?: throw RuntimeException("NULL")

                braintreeHostedFieldsInstanceCopy.tokenize { error, bTreePayload ->
                    if (error != null) {
                        error(ActionMessage("cc_add", "Failed to add CC data because of: $error"))
                        errorHandler()
                    } else {
                        if (bTreePayload == null) {
                            error(ActionMessage("cc_add", "Failed to add CC Payment data because of null payload"))
                            errorHandler()
                        } else
                            mainScope.launch {
                                if (postPayment(bTreePayload.nonce, deviceData, bTreePayload.type).await()) {
                                    historyChange()
                                } else {

                                    errorHandler()
                                }
                            }
                    }
                }
            } catch (e: Throwable) {
                console.log(e)
                error(ActionMessage("cc_add", "An error $e was thrown"))
                errorHandler()
            }
        }
    } else {
        error(ActionMessage("cc_add", "Braintree fields are null"))
        errorHandler()
    }
}

suspend fun loadToken(): HttpResponse = client.get(path = "/payments/token") {}
