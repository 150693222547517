package components

import react.FC
import react.Props
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import kotlin.js.Date

@JsModule("./images/logo/logo192x192.png")
@JsNonModule
external val logo: dynamic

@JsModule("./styles/footer.css")
@JsNonModule
external val footerStyle: dynamic


val rooamFooter = FC<Props> {

	div {
		className = "section footer"
		div {
			className = "container text-center"
			span {
				className = "foot-logo"
				img {
					alt = "Rooam Icon"
					src = logo
				}
			}
			p {
				className = "small font-weight-600 no-bottom"
				+"Powered by Rooam"
			}
			div {
				className = "link-box text-center no-bottom"
				a {
					href = "mailto:help@rooam.co"
					target = AnchorTarget._blank
					className = "btn block margin outline"
					+"Give Feedback"
				}
				a {
					href = "https://rooam.co"
					target = AnchorTarget._blank
					className = "btn block margin outline"
					+"About Rooam"
				}
			}
			p {
				className = "small text-grey no-bottom"
				+"\u00A9 Copyright ${Date().getFullYear()} - Rooam Inc."
			}
		}

	}
}


