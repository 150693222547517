package pages.tab

import api.Feed
import api.getFeed
import components.fullPageLoading
import components.layout
import components.seo
import imported.sentry.getCurrentHub
import kotlinx.browser.localStorage
import kotlinx.coroutines.async
import kotlinx.coroutines.cancel
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.flow
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import mainScope
import org.w3c.dom.get
import react.FC
import react.Props
import react.router.NavigateFunction
import react.router.useNavigate
import react.router.useParams
import react.useEffectOnce
import react.useState
import kotlin.js.Date
import kotlin.js.json

external interface TabEmptyProps : Props {
	var tabNumber: String?
}

var defaultFeed = Feed(
	tab = null,
	open = true
)
val tab = FC<TabEmptyProps>("TabOpenPage") { props ->
	val (liveFeed, setFeed) = useState(
		localStorage.get("FEED")?.let { Json.decodeFromString(it) } ?: defaultFeed
	)
	val updateFeed = { feed: Feed ->
		setFeed(feed)
	}
	useParams()
	val history = useNavigate()
	useEffectOnce {
		val span = getCurrentHub().getScope().getTransaction().startChild(
			json(
				"op" to "pullFeed",
				"description" to "Pulls latest feed data"
			)
		)
		val feed = mainScope.async { runFeed(history, updateFeed) }
		this.cleanup {
			feed.cancel("Closing")
			span.finish()
		}

	}
	layout {
		seo {
			titleTemplate = "Your Tab - %s"
		}
		if (liveFeed.tab == null) {
			fullPageLoading {}
		} else {
			if (liveFeed.tab.items.isEmpty()) {
				tabEmpty {

					tabNumber = liveFeed.tab.tabNumber

				}
			} else {
				tabOrdered {
					feed = liveFeed

				}
			}
		}
	}
}


fun feedFlow() = flow {
	while (true) {

			console.log(this)
			console.log("WAIT 10 SEC", Date())
			delay(10000)
			console.log("END OF WAIT", Date())
			emit(getFeed())

		}

}

suspend fun runFeed(
	history: NavigateFunction,
	updateFeed: (Feed) -> Unit
) {
	feedFlow().collect {
		if (it == null) {
			history("../phone")
			return@collect
		}
		defaultFeed = it
		localStorage.setItem("FEED", Json.encodeToString(it))
		updateFeed(it)
		if (it.voided) {
			history("../voided")
		} else {
			if (!it.open)
				history("../complete")
		}
	}
}



