package components.explainer

import csstype.px
import kotlinext.js.jso
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img

@JsModule("./images/explainer/payments/amex.jpg")
@JsNonModule
external val amex: dynamic

@JsModule("./images/explainer/payments/visa.jpg")
@JsNonModule
external val visa: dynamic

@JsModule("./images/explainer/payments/apple-pay.jpg")
@JsNonModule
external val applePayLogo: dynamic


@JsModule("./images/explainer/payments/mastercard.jpg")
@JsNonModule
external val mastercard: dynamic

@JsModule("./images/explainer/payments/google-pay.jpg")
@JsNonModule
external val googlePayLogo: dynamic

@JsModule("./images/explainer/payments/maestro.jpg")
@JsNonModule
external val maestro: dynamic

@JsModule("./images/explainer/payments/discover.jpg")
@JsNonModule
external val discover: dynamic

@JsModule("./images/explainer/payments/diners.jpg")
@JsNonModule
external val diners: dynamic

val paymentOptions = FC<Props> {

	div {
		className = "row payments float-middle clearfix"
		style = jso { maxWidth = 400.px }
		paymentOption {
			src = visa
			alt = "Visa Payment"
		}
		paymentOption {
			src = amex
			alt = "American Express Payment"
		}
		paymentOption {
			src = applePayLogo
			alt = "Apple Pay Payment"
		}
		paymentOption {
			alt = "Mastercard Payment"
			src = mastercard
		}
		paymentOption {
			src = googlePayLogo
			alt = "Google Pay Payment"
		}
		paymentOption {
			src = maestro
			alt = "Maestro Payment"
		}
		paymentOption {
			src = discover
			alt = "Discover Payment"
		}
		paymentOption {
			src = diners
			alt = "Diners Payment"
		}


	}
}


external interface ImageProps : Props {
	var src: String
	var alt: String
}

val paymentOption = FC<ImageProps> { props ->
	div {
		className = "column four-even"
		div {
			className = "padding"
			img {
				src = props.src
				alt = props.alt
			}
		}
	}
}
