package components

import api.VenueDetails
import components.utils.orbit
import kotlinx.browser.sessionStorage
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.useParams

val fullPageLoading = FC<Props> {
	val codeProps = useParams()
	val venueDetails = sessionStorage["VENUE-${codeProps["code"]}"]?.let { Json.decodeFromString<VenueDetails>(it) }
	div {
		className = "section full-page"
		div {
			className = "middle-content"
			div {
				className = "container text-center"
				div {
					className = "orbit-box small no-bottom-margin"
					orbit()
					span {
						className = "icon flashing"
						svg {
							className = "svg-icon"
							viewBox = "0 0 24 24"
							path {
								d =
									"M6 12c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3zm9 0c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3zm9 0c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z"

								fill = "#939597"
							}
						}
					}
				}


				p {
					className = "medium font-weight-500 text-grey no-bottom"
					+"Loading ${venueDetails?.name} - Web Tab..."
				}
			}
		}
	}
}

