package components.faq

import csstype.pct
import kotlinext.js.jso
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.ul
import react.dom.svg.ReactSVG
import react.useState


@JsModule("./styles/faqs.css")
@JsNonModule
external val faqStyle: dynamic

external interface FaqProps : Props {
	var rows: Array<Question>
}

external interface Question : Props {
	var title: String
	var lines: Array<String>
}

val Faq = FC<FaqProps> { props ->
	ul {
		className = "faq"
		props.rows.forEach {
			li {
				hr {}
				SingleQuestion {
					title = it.title
					lines = it.lines
				}
			}
		}
	}

}

val SingleQuestion = FC<Question> { props ->
	var active by useState(false)

	ReactHTML.button {
		className = "section less faq-btn text-left"
		if (active) className += " active"

		onClick = {
			it.preventDefault()
			active = !active
		}
		tabIndex = 0

		ReactHTML.div() {
			className = "padding"
			ReactHTML.p() {
				className = "text-white font-weight-600 no-top-bottom"
				style= jso {
					maxWidth = 90.pct
				}
				+props.title

			}
			ReactHTML.span() {
				className = "expand-btn"
				ReactHTML.span() {
					className = "icon"
					ReactSVG.svg() {
						className = "svg-icon"
						viewBox = "0 0 24 24"
						ReactSVG.path {
							d ="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
								//"M24 12c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12zm-18.005-1.568l1.415-1.414 4.59 4.574 4.579-4.574 1.416 1.414-5.995 5.988-6.005-5.988z"
							fill = "#bebebe"

						}
					}
				}
			}
		}
	}
	div {

		className = "section faq-bg faq-info"
		className += " no-top-bottom"
		if (active) {
			className += " active"
		}
		p{
		props.lines.forEach {
			+it
			br{}
		}
		}

	}
}

