package components.summary

import api.TabItem
import components.container
import components.item.ItemSmall
import kotlinx.html.js.onClickFunction
import react.Props
import react.dom.div
import react.dom.li
import react.dom.ul
import react.fc
import react.useState
import styled.css
import styled.styledA
import styled.styledDiv

external interface SummaryItemsProps : Props {
    var items: List<TabItem>
}

sealed interface ViewTitles {
    fun next(): ViewTitles
    fun text(): String
}

object ViewItems : ViewTitles {
    override fun next(): ViewTitles = HideItems

    override fun text(): String = "View Items"
}

object HideItems : ViewTitles {
    override fun next(): ViewTitles = ViewItems

    override fun text(): String = "Hide Items"
}

val SummaryItems = fc<SummaryItemsProps>("Summary Items") { props ->
    var title by useState(ViewItems as ViewTitles)

    div("section less no-bottom") {
        container {
            styledA {
                css {
                    classes += "btn"
                    classes += "no-animation"
                    classes += "small"
                    classes += "outline"
                    classes += "white"
                    if (title == HideItems) {
                        classes += "active"
                    }
                }
                attrs.onClickFunction = {
                    it.preventDefault()
                    title = title.next()
                }
                +title.text()
            }
        }
    }
    styledDiv {
        css {
            classes += "section"
            classes += "summary-items"
            classes += "less"
            classes += "no-bottom"
            if (title == HideItems) {
                classes += "active"
            }
        }
        container {
            div("section no-top-bottom") {
                ul("ordered-items small clearfix") {
                    for (item in props.items) {
                        li {
                            ItemSmall {
                                this.attrs.itemName = item.name
                                this.attrs.itemTotal = item.price
                                this.attrs.itemPrice = item.pricePerUnit
                                this.attrs.quantity = item.quantity
                            }
                        }
                    }
                }
            }
        }
    }

}