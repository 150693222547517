package api

import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import kotlinx.browser.sessionStorage
import kotlinx.coroutines.asPromise
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import mainScope
import org.w3c.dom.get


@Serializable
data class VenueDetails(val name: String, val logo: String, val image: String, val address: String)


val getVenueDetails = { code: String,
                        apply: (VenueDetails) -> Unit ->
    console.log(code)
    mainScope.launch {
        val get = sessionStorage["VENUE-$code"]
        if (get == null) {
            val venueDetails = client.get<HttpResponse>(path = "/venue/$code") {}.receive<VenueDetails>()
            sessionStorage.setItem("VENUE-$code", Json.encodeToString(venueDetails))
            apply(venueDetails)
        } else {
            apply(Json.decodeFromString(get))
        }

    }

}

