package api

import api.storage.storeRooamHeader
import io.ktor.client.call.*
import io.ktor.client.features.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import mainScope

fun openTab(
    placeCode: String?,
    errorHandle: () -> Unit,
    overdueHandle: () -> Unit,
    update: suspend (TabOpenResponse) -> Unit
) = mainScope.launch {
        val post: HttpResponse =
            client.post<HttpResponse>(path = "/tab", body = OpenRequest(placeCode = placeCode)) {
                addSessionHeader()
                header("Content-Type", ContentType("application", "json"))
                expectSuccess = false
            }.also { it.storeRooamHeader() }
        if (post.status == HttpStatusCode.PaymentRequired) {
            overdueHandle()
            return@launch
        }
        if (post.status != HttpStatusCode.OK) {
            errorHandle()
            return@launch
        }
        val receive = post.receive<TabOpenResponse>()
        val feed = Feed(tab = Tab(tabNumber = receive.tabNumber, arrayOf()), open = true)
        localStorage.setItem("FEED", Json.encodeToString(feed))
        update(receive)
    }

fun closeTab(
    placeCode: String?,
    tipPair: Pair<Int, String>,
    nonce: String?,
    deviceData: String?,
    errorInfo: () -> Unit,
    toOverdue: () -> Unit,
    historyMove: () -> Unit
) =
    mainScope.launch {

        val post: HttpResponse =
            client.post<HttpResponse>(
                path = "/close", body = ApiCloseTabRequest(
                    placeCode = placeCode,
                    nonce = nonce,
                    deviceData = deviceData,
                    tip = if (tipPair.first != -1) tipPair.first.toString() else null,
                    tipAmount = if (tipPair.first == -1) tipPair.second else null
                )
            ) {
                addSessionHeader()
                header("Content-Type", ContentType("application", "json"))
                expectSuccess = false
            }.also { it.storeRooamHeader() }
        if (post.status != HttpStatusCode.OK) {
            error(ActionMessage("close_tab", "Error with status: ${post.status}"))
            errorInfo()
            if (post.status == HttpStatusCode.PaymentRequired) {
                log(ActionMessage("close_tab", "Going to overdue"))
                toOverdue()
            }
        } else {
            log(ActionMessage("close_tab", "Success"))
            localStorage.removeItem("FEED")
            historyMove()
        }
    }

fun postTabVoidedReason(reason: String, stateChanges: (MessageSendState) -> Unit) =
    mainScope.launch {
            client.post<HttpResponse>(path = "/voided/reason", body = VoidedReason(reason)) {
                addSessionHeader()
                header("Content-Type", ContentType("application", "json"))
            }.also { it.storeRooamHeader() }
        stateChanges(MessageSendState.SUCCESS)
    }


@Serializable
data class VoidedReason(val reason: String)

@Serializable
data class OpenRequest(val placeCode: String?)

@Serializable
data class TabOpenResponse(val tabNumber: String?)

@Serializable
data class ApiCloseTabRequest(
    val placeCode: String? = null,
    val tip: String? = null,
    val tipAmount: String? = null,
    val nonce: String? = null,
    val deviceData: String? = null
)

