package pages.explainer

import components.*
import components.explainer.*
import components.icons.infoIcon
import csstype.px
import kotlinext.js.jso
import react.CSSProperties
import react.FC
import react.Props
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.dom.Link

@JsModule("./styles/explainer.css")
@JsNonModule
external val explainerStyle: dynamic

@JsModule("./images/explainer/hero/hero-product-grid.png")
@JsNonModule
external val heroImage: dynamic

external interface ExplainerProps : Props

val Explainer = FC<ExplainerProps> {

	layout {
		seo {
			titleTemplate = "%s - What is a Web Tab?"
		}
		div() {
			className = "section no-top-bottom"
			location {
				isLong = true
				showBack = false
				showMenu = false
				isExplainer = true
			}
		}
		div() {
			className = "section no-top-bottom"
			floatText {}
		}
		div {
			className = "section"
			div {
				className = "container text-center"
				div {
					className = "float-middle"
					style = jso<CSSProperties>().apply {
						maxWidth = 300.px
					}
					cards {}
				}
			}
		}
		div() {
			className = "section less no-bottom"
			div() {
				className = "container text-center"
				p() {
					className = "float-middle no-top-bottom"
					style = jso {
						maxWidth = 480.px
					}
					+"""You've landed on a Web Tab link—a fast and secure way to open and close your bar tab with your phone. Keep your tab open all night and when you’re ready to leave, simply tap 'Close Tab'."""
				}
				div() {
					className = "section"
					Link {
						className = "btn red block"
						to = "../phone"
						+"""Get Started"""
					}
				}

			}
		}
		div {
			className = "section less"
			container {
				dividerWithText {
					text = "scroll to discover more"
				}
			}
		}
		div {
			className = "section less no-bottom"
			div {
				className = "container text-center"
				div {
					className = "icon hero"
					infoIcon {}
				}
				h1 {
					className = "big no-top-bottom"
					+"How it works?"
				}
			}
		}
		div {
			className = "section less no-bottom"
			howItWorks {}
		}
		div {
			className = "section largest no-bottom"
			div {
				className = "containter text-center"
				h1 {
					className = "big float-middle no-top-bottom"
					style = jso {
						maxWidth = 380.px
					}
					+"Pay with any payment method you wish."
				}
			}
		}
		div {
			className = "section less no-bottom"
			div {
				className = "section less no-bottom"
				container {
					paymentOptions {}
				}
			}
			div {
				className = "container text-center"
				div {
					className = "section no-bottom"
					Link {
						to = "../phone"
						className = "btn block"
						+"Get Started"
					}
				}
			}

		}
		div {
			className = "section largest no-bottom"
			div {
				className = "container text-center"
				div {
					className = "icon hero"
					svg {
						className = "svg-icon"
						viewBox = "0 0 24 24"
						path {
							fill = "#555759"
							d =
								"M9 3h6v2h-6v-2zm3 15l7-8h-4v-4h-6v4h-4l7 8zm3-16v-2h-6v2h6zm3.213-.246l-1.213 1.599c2.984 1.732 5 4.955 5 8.647 0 5.514-4.486 10-10 10s-10-4.486-10-10c0-3.692 2.016-6.915 5-8.647l-1.213-1.599c-3.465 2.103-5.787 5.897-5.787 10.246 0 6.627 5.373 12 12 12s12-5.373 12-12c0-4.349-2.322-8.143-5.787-10.246z"
						}
					}
				}
				h1 {
					className = "big float-middle no-top"
					style = jso {
						maxWidth = 400.px
					}
					+"Save this link to your home screen for easy access."
				}
				p {
					className = "float-middle no-top-bottom"
					style = jso {
						maxWidth = 420.px
					}
					+"Want to easily find this link and open your tab before you arrive at the show or location?"
				}
				div {
					className = "section no-bottom"
					button {
						className = "btn block"

						+"Save Link to Home Screen"
					}
				}
			}
		}
		div {
			className = "section large no-bottom"
			div {
				className = "section no-top"
				img {
					src = heroImage
					alt = "Hero of Web Tab"
				}
			}
			div{
				className="container text-center"
				h1{
					className="float-middle no-top-bottom"
					style=jso{
						maxWidth=450.px
					}
					+"The fastest way to open and close your tab. No app download or account creation needed."
				}
				div{
					className="section no-bottom"
					Link {
						to = "../phone"
						className = "btn red block"
						+"Get Started"
					}
				}
			}
		}
		div{
			className="section largest no-bottom"
			div{
				className="container text-center"
				div{
					className="icon hero"
					svg {
						className="svg-icon"
						xmlns = "http://www.w3.org/2000/svg"
						viewBox = "0 0 24 24"
						path {
							fill="#555759"
							d =
								"M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"
						}
					}
				}
				h1{
					className="section large no-top-bottom"
					+"Got questions?"
				}
				explainerFAQ{}
				div{
					className="section large no-bottom"
					Link {
						to = "../phone"
						className = "btn block"
						+"Get Started"
					}
				}
			}
		}
		rooamFooter {}

	}

}
