package google

import api.*
import google.import.GooglePayClient
import google.import.PaymentsClient
import imported.braintree.GooglePayOptions
import imported.braintree.TransactionInfo
import imported.braintree.TransactionProperties
import imported.braintree.google.createGooglePay
import kotlinext.js.jso
import kotlinx.coroutines.async
import kotlinx.coroutines.await
import mainScope
import suspendedBrainTreeClient

fun GooglePayClient(env: String): GooglePayClient = PaymentsClient(jso { environment = env })


external interface IsReadyToPayResponse {
	var result: Boolean?
	var paymentMethodPresent: Boolean?
}

external interface GoogleIsReadyToPayParams {
	var apiVersion: Int
	var apiVersionMinor: Int
	var allowedPaymentMethods: dynamic
	var existingPaymentMethodRequired: Boolean?

}

fun GoogleIsReadyToPayParams(): GoogleIsReadyToPayParams = js("{}")


fun prepareGooglePay(
	totalPay: String?,
	storeName: suspend (String) -> Unit,
	loading: (Boolean) -> Unit,
	navigation: (String) -> Unit
) =
	mainScope.async {
		console.log("Starting prepare google pay")
		val brainTreeClient = suspendedBrainTreeClient.await()
		console.log("got client", brainTreeClient)


		val googlePaymentInstance = createGooglePay(GooglePayOptions().apply {
			this.googlePayVersion = 2
			this.client = brainTreeClient
			this.googleMerchantId = googlePayMerchantID
		}).await()

		console.log(googlePayEnvironment)
		val googleClient = GooglePayClient(googlePayEnvironment)
		val payResponse = googleClient.isReadyToPay(jso {
			apiVersion = 2
			apiVersionMinor = 0
			allowedPaymentMethods = googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods
			existingPaymentMethodRequired = true
		}).await()
		if (payResponse.result == true) {
			return@async {
				mainScope.async {
					loading(true)

					val createPaymentDataRequest =
						googlePaymentInstance.createPaymentDataRequest(TransactionProperties().apply {
							transactionInfo = TransactionInfo().apply {

								this.totalPriceStatus = if (totalPay != null) "FINAL" else "NOT_CURRENTLY_KNOWN"
								if (totalPay != null) {
									this.totalPrice = totalPay
								}
								this.currencyCode = "USD"
							}
						})

					//JS DIRECT
					val cardPaymentMethod = createPaymentDataRequest.allowedPaymentMethods[0]
					cardPaymentMethod.parameters.billingAddressRequired = true
					cardPaymentMethod.parameters.billingAddressParameters = jso { format = "FULL" }

					val unparsedPaymentData = googleClient.loadPaymentData(createPaymentDataRequest).then(
						{ it }
					) {
						error(ActionMessage("google_pay", "Google pay failed: $it"))
						loading(false)
					}.await()
					val deviceData = collectDeviceData((brainTreeClient))
						.await()
						.deviceData
					val parsedPaymentData = googlePaymentInstance.parseResponse(unparsedPaymentData).await()
					try {
						storeName(unparsedPaymentData.asDynamic().paymentMethodData.info.billingAddress.name)
						if (totalPay == null) {
							if (!postPayment(parsedPaymentData.nonce, deviceData, "GOOGLE_PAY").await()) {
								throw RuntimeException("Payment not added")
							}
						}
						navigation(parsedPaymentData.nonce)
					} catch (e: Throwable) {
						loading(false)
					}
				}
			}
		} else
			return@async {}

	}

