package components.explainer

import kotlinx.css.maxWidth
import kotlinx.css.px
import react.Props
import react.dom.div
import react.fc
import styled.css
import styled.styledH1

val floatText = fc<Props>{

	div("container text-center"){
		styledH1 {
			css {
				classes+="big float-middle no-bottom"
				maxWidth= 420.px
			}
			+"Open a tab without ever handing over your credit card."
		}
	}

}
