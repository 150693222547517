package pages

import api.StateResponse
import api.getVenueDetails
import components.fullPageLoading
import components.layout
import components.seo
import io.ktor.client.call.*
import io.ktor.http.*
import kotlinx.coroutines.launch
import locationBase
import mainScope
import react.FC
import react.Props
import react.router.NavigateFunction
import react.router.Params
import react.router.useNavigate
import react.router.useParams
import react.useEffect
import react.useLayoutEffect


external interface SlashPageProps : Props


val slashPage = FC<SlashPageProps> {
    val location = useParams()
    val history = useNavigate()
    useLayoutEffect(location) {
        getVenueDetails(location.get("code") ?: "") {
        }
    }
    useEffect {
        moveToNext(history, location)
    }
    layout {
        seo {
            titleTemplate = "Loading - %s"
        }
        fullPageLoading { }
    }
}

val moveToNext =
    { history: NavigateFunction, location:  Params ->
        mainScope.launch {
            val state = getState()

            if (state.status != HttpStatusCode.OK) {
                history("${locationBase(location)}/phone")
            } else {
                val (nextPath, placeCode) = state.receive<StateResponse>()
                history("${locationBase(placeCode, location)}/$nextPath")
            }
        }
    }
