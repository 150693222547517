import api.addSessionHeader
import api.client
import api.storage.storeRooamHeader
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.serialization.Serializable

suspend fun sendFirstLastToAccountData(firstName: String, lastName: String) =
	client.post<HttpResponse>(path = "/account", body = FirstLast(firstName, lastName)) {
		addSessionHeader()
		header("Content-Type", ContentType("application", "json"))
	}
		.also { it.storeRooamHeader() }


@Serializable
data class FirstLast(val first: String, val last: String)
