package api

import api.storage.storeRooamHeader
import imported.braintree.DataCollectorOptions
import imported.braintree.data.braintreeDataCollector
import io.ktor.client.call.*
import io.ktor.client.features.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.Job
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import mainScope
import react.StateSetter

val loadPayments = { setPayments: StateSetter<List<Payment>> ->
    mainScope.async {
        val get: HttpResponse = client.get<HttpResponse>(path = "/payments") {
            addSessionHeader()
            expectSuccess = false
        }.also { it.storeRooamHeader() }
        val paymentsList: List<Payment> = get.receive()
        setPayments(paymentsList)
    }
}

val postPayment = { nonce: String, deviceData: String?, type: String ->
    mainScope.async {
        val post: HttpResponse =
            client.post<HttpResponse>(
                path = "/payments",
                body = PaymentRequest(nonce = nonce, deviceData = deviceData, paymentType = type)
            ) {
                addSessionHeader()
                header("Content-Type", ContentType("application", "json"))
                expectSuccess = false
            }.also { it.storeRooamHeader() }
        if (post.status != HttpStatusCode.OK) {
            error(ActionMessage("payment_add", "failed to add payment"))
            return@async false
        }
        return@async true
    }
}

val updatePayment = { payment: Payment,
                      errorHandle: () -> Unit,
                      navigation: () -> Unit ->
    mainScope.launch {
        val put: HttpResponse =
            client.put<HttpResponse>(
                path = "/payments/${payment.id}",
                body = UpdatePaymentRequest(paymentType = payment.type, default = true)
            ) {
                addSessionHeader()
                header("Content-Type", ContentType("application", "json"))
                expectSuccess = false
            }.also { it.storeRooamHeader() }
        if (put.status != HttpStatusCode.OK) {
            errorHandle()
        } else
            navigation()
    }
}

fun deletePayment(payment: Payment, disable: (Boolean) -> Unit, navigation: () -> Unit): Job {
    return mainScope.launch {
        disable(true)
        val put: HttpResponse =
            client.delete<HttpResponse>(
                path = "/payments/${payment.id}",
            ) {
                addSessionHeader()
                header("Content-Type", ContentType("application", "json"))
                expectSuccess = false
            }.also { it.storeRooamHeader() }
        if (put.status != HttpStatusCode.OK) {
            disable(false)
        } else
            navigation()
    }
}

fun collectDeviceData(braintreeClientAwaited: Any) =
    braintreeDataCollector(DataCollectorOptions().apply {
        this.client = braintreeClientAwaited
        this.kount = true
    })


@Serializable
data class PaymentRequest(val paymentType: String, val nonce: String, val deviceData: String? = null)

@Serializable
data class UpdatePaymentRequest(val paymentType: String, val default: Boolean = true)
