package api

import kotlinx.coroutines.delay
import kotlin.time.Duration.Companion.seconds
import kotlin.time.ExperimentalTime
import kotlin.time.TimeSource

@OptIn(ExperimentalTime::class)
var lastUpdate = TimeSource.Monotonic.markNow()

@OptIn(ExperimentalTime::class)
val updateMark = {
    lastUpdate = TimeSource.Monotonic.markNow()
}

@OptIn(ExperimentalTime::class)
fun checkIfStale() {
    lastUpdate.plus(10.seconds).hasPassedNow()
}

suspend fun delayTenSeconds(){
    delay(10_000)
}
