package components.tao.api

import api.addSessionHeader
import api.addSessionStaticHeader
import api.client
import api.storage.storeRooamHeader
import io.ktor.client.call.*
import io.ktor.client.features.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import mainScope

fun getRewards(apply: (TaoRewardsResponse) -> Unit) = mainScope.launch {
	client.get<HttpResponse>(path = "tao")
	{
		header("Content-Type", ContentType("application", "json"))
		addSessionStaticHeader()
		expectSuccess = false
	}.apply { apply(receive()) }

}


fun redeemRewards(dollars: String, apply: (TaoRedeemStatus) -> Unit) {
	mainScope.launch {
		apply(
			client.post<HttpResponse>(
				path = "/tao",
				body = TaoRedeemRequest(dollars)
			) {
				header("Content-Type", ContentType("application", "json"))
				addSessionHeader()
				expectSuccess = false
			}.also { it.storeRooamHeader() }
				.receive()
		)
	}
}

@Serializable
data class TaoRedeemRequest(val amount: String)


@Serializable
data class TaoRewardsResponse(val amount: String? = null, val used: String? = null)

@Serializable
data class TaoRedeemStatus(val status: String)
