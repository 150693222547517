package components.error

import components.path
import components.viewBox
import react.Props
import react.dom.span
import react.dom.svg
import react.fc
import styled.inlineStyles

val StandardError = fc<Props>{
    span("icon") {
        svg("svg-icon") {
            attrs.viewBox = "0 0 24 24"
            path(
                d = "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z"
            ) {
                inlineStyles {
                    declarations["fill"] = "#ec0b19"
                }

            }
        }
    }
}
val ConnectionError = fc<Props>{
    span("icon") {
        svg("svg-icon"){
            attrs.viewBox="0 0 24 24"
            path("M14.9 19.143l-2.78 2.779c-2.771 2.772-7.268 2.772-10.041 0-2.772-2.773-2.771-7.269 0-10.041l2.779-2.779 2.121 2.121-2.779 2.779c-1.598 1.598-1.598 4.2 0 5.797 1.6 1.6 4.201 1.6 5.799 0l2.779-2.777 2.122 2.121zm-3.02-17.063l-2.779 2.779 2.121 2.121 2.78-2.779c1.598-1.598 4.199-1.598 5.795.001 1.602 1.598 1.602 4.199.004 5.797l-2.779 2.779 2.121 2.121 2.779-2.778c2.771-2.773 2.771-7.269 0-10.041-2.774-2.772-7.27-2.772-10.042 0zm-5.945-.795l1.44-.204.438 3.083-1.438.205-.44-3.084zm-4.855 6.09l.206-1.441 3.084.44-.206 1.44-3.084-.439zm4.793-2.521l-1.028 1.03-2.205-2.203 1.029-1.029 2.204 2.202zm12.191 17.86l-1.441.204-.438-3.083 1.439-.205.44 3.084zm4.856-6.09l-.207 1.441-3.084-.439.207-1.441 3.084.439zm-4.793 2.52l1.027-1.029 2.205 2.204-1.029 1.029-2.203-2.204z"){
                inlineStyles {
                    declarations["fill"]="#ec0b19"
                }
            }
        }
    }
}