package components

import org.w3c.dom.events.Event
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.p
import react.useEffectOnce
import react.useState


external interface AlertModalProps : Props {
	var text: String
	var title: String
	var confirmActionPressed: (Event) -> Unit
}

val modalAlert = FC<AlertModalProps>("alertModal") { props ->
	val (active, setActive) = useState(false)
	useEffectOnce {
		setActive(true)
	}
	div {
		className = "section full-page modal ${if (active) "active" else ""}"
		div {
			className = "modal-overlay"
		}
		div {
			className = "middle-content"
			container {
				div {
					className = "modal-box"
					div {
						className = "padding-modal text-center"
						h2 {
							className = "text-black no-top-bottom"
							+props.title
						}
						p {
							className = "text-grey"
							+props.text
						}
						div {
							className = "link-box no-bottom"
							div {
								className = "row"
								div {
									className = "column full"
									div {
										className = "padding"
										a {
											className = "btn black"
											onClick = { props.confirmActionPressed(it.nativeEvent) }
											+"Ok"
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

}
