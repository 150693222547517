package components

import api.Payment
import functions.getImageFromPayment
import react.FC
import react.Props
import react.dom.aria.AriaRole
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.useEffect
import react.useState


external interface PaymentSelectProps : Props {
	var isPaymentSelected: Boolean?
	var payment: Payment
	var allowDelete: Boolean?
	var reloadList: () -> Unit
	var onSelectPayment: (Payment) -> Unit
}

data class PaymentState(
	var text: String,
	var type: String,
)

val paymentSelect = FC<PaymentSelectProps>("paymentSelect") { props ->
	val (paymentState, stateUpdate) = useState(
		PaymentState(type = props.payment.type, text = props.payment.label)
	)
	useEffect(props.payment) {
		stateUpdate(PaymentState(type = props.payment.type, text = props.payment.label))
	}
	div {
		className = "padding"
		span {
			className = "payment-card-icon ${if (paymentState.type == "LOADING") "flashing" else ""}"
			img {
				src = getImageFromPayment(props.payment)
			}
		}
		p {
			className = if (paymentState.type == "LOADING")
				"medium font-weight-500 text-grey no-top-bottom"
			else
				"medium font-weight-500 text-white no-top-bottom"
			+paymentState.text
		}
		if (props.allowDelete == true && props.payment.type == "CC")
			deletePaymentButton {

				this.payment = props.payment
				this.disable = {
					if (it) {
						stateUpdate(PaymentState(type = "LOADING", text = "Deleting Payment..."))
					} else {
						stateUpdate(PaymentState(type = props.payment.type, text = props.payment.label))
					}
				}
				this.reload = props.reloadList

			}
		if (props.payment.type != "LOADING") {
			a {
				className = if (props.isPaymentSelected == true) "radio-btn no-select active" else "radio-btn no-select"

				onClick = {
					it.preventDefault()
					props.onSelectPayment(props.payment)
				}
				role = AriaRole.button
				tabIndex = 0

				span {
					className = "icon tick"
					svg {
						className = "svg-icon"
						viewBox = "0 0 24 24"
						path {
							d =
								"M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
							fill = "#ffffff"
						}
					}

				}
				span {
					className = "icon"
					svg {
						className = "svg-icon"
						viewBox = "0 0 24 24"
						path {
							d =
								"M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z"
							fill = "#ffffff"
						}
					}
				}
			}
		}
	}
}
