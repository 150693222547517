package components

import components.animations.RotatingLoader
import react.FC
import react.Props
import react.dom.html.ReactHTML.div

val ItemsLoading = FC<Props>("loadingItems") {

    div {
        className="section no-top-bottom"
        div {
            className="message loading"
                RotatingLoader {
                    text = "Loading Items..."
                }
            }
        }

}

