package pages.tab

import api.*
import apple.applyApplePay
import apple.canUseApplePay
import components.*
import csstype.Display.Companion.block
import csstype.Display.Companion.none
import functions.sum
import functions.tipRate
import google.prepareGooglePay
import io.ktor.client.call.*
import kotlinx.browser.localStorage
import kotlinx.browser.sessionStorage
import kotlinx.coroutines.async
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import locationBase
import mainScope
import pages.TabCompleteProps
import pages.Tip
import pages.Tips
import pages.getTips
import react.FC
import react.Props
import react.css.css
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.*
import react.useEffect
import react.useState
import suspendedBrainTreeClient
import taoDiscount

external interface TabProps : Props {
	var feed: Feed
}

var payGoogleBehavior: (() -> Any)? = null
var payAppleBehavior: (() -> Unit)? = null
var tabPagedeviceData: String? = null

val tabOrdered = FC<TabProps> { props ->
	val history = useNavigate()
	val codeProps = useParams()
	val (tips, setNewTips) = useState(localStorage.getItem("TIPS")?.let {
		Json.decodeFromString(it)
	} ?: Tips(listOf(Tip(18), Tip(20), Tip(22)), Tip(20)))
	val (tipValue, setTipValue) = useState(
		tips.defaultTip.percentage to tipRate(
			sum(props.feed.totals?.subTotal,props.feed.totals?.discount),
			tips.defaultTip.percentage
		)
	)
	val (closing, setClosing) = useState(false)
	val (error, setError) = useState(false)
	val (showApplePayAlert, setShowApplePayAlert) = useState(false)
	val calculateTotal = calculateTotal(props, tipValue)
	val (modalShow, setShowModal) = useState(false)
	val isPaytronix = sessionStorage.getItem("taoEnabled")
	useEffect {
		if (localStorage.getItem("TIPS") == null) {
			mainScope.launch {
				val receive = getTips().receive<Tips>()
				setTips(receive)
				setNewTips(receive)
				if (tipValue.first != -1) {
					if (receive.tips.none { it.percentage == tipValue.first }) {
						setTipValue(
							receive.defaultTip.percentage to tipRate(
								sum(props.feed.totals?.subTotal,props.feed.totals?.discount),
								receive.defaultTip.percentage
							)
						)
					}
				}
			}
		}
	}
	useEffect(calculateTotal) {
		mainScope.launch {
			val braintreeClientAwaited = suspendedBrainTreeClient.await()
			tabPagedeviceData = collectDeviceData(braintreeClientAwaited).await().deviceData
			payGoogleBehavior = prepareGooglePay(

				calculateTotal,
				{ mainScope.async { } },
				{
					setClosing(it)
					if (!it) {
						setError(!it)
					}
				}
			) {
				closeTab(codeProps["code"], tipValue, it, tabPagedeviceData, {
					setError(true)
					setClosing(false)
					error(

						ActionMessage("close_tab", "Closing tab with tip = $tipValue , $calculateTotal FAILED")
					)
				}, { history("${locationBase(codeProps)}/overdue") }
				) {
					log(ActionMessage("close_tab", "Closing tab with tip = $tipValue success $it"))
					pushToComplete(history, codeProps)
				}
			}.await()
			payAppleBehavior = applyApplePay(
				{ },
				calculateTotal,
				{ _, _ -> mainScope.async { } },
				{
					setClosing(it)
					if (!it) {
						setError(!it)
					}
				}) {

				closeTab(codeProps["code"], tipValue, it, tabPagedeviceData, {
					setError(true)
					setClosing(false)
					error(
						ActionMessage("close_tab", "Closing tab with tip = $tipValue , $calculateTotal FAILED")
					)
				},
					{ history("${locationBase(codeProps)}/overdue") }) {
					log(
						ActionMessage("close_tab", "Closing tab with tip = $tipValue success $it")
					)
					pushToComplete(history, codeProps)
				}
			}.await()
		}

	}

	divPage {
		if (showApplePayAlert) {
			confirmModal {
				showIcon = false
				title = "Apple Pay unavailable."
				text =
					"You cannot use Apple Pay with current browser. Please switch to Safari or choose another payment method."
				confirmButtonText = "Change"
				confirmActionPressed = {
					it.preventDefault()
					setShowApplePayAlert(false)
					history("${locationBase(codeProps)}/select")
				}
				cancelActionPressed = {
					it.preventDefault()
					setShowApplePayAlert(false)
				}
			}
		}
		if (modalShow) confirmModal {
			showIcon = true
			title = "Are you sure?"
			text =
				"Make sure to check your items and your tip before closing your tab. When you are ready, tap 'Confirm' to close you tab."
			booleanShowModal = modalShow
			this.cancelActionPressed = {
				it.preventDefault()
				setShowModal(false)
			}
			this.confirmActionPressed = {
				it.preventDefault()
				setClosing(true)
				setError(false)
				setShowModal(false)
				if (props.feed.paymentMethod?.type == "GOOGLE_PAY") {
					payGoogleBehavior?.invoke()
				} else if (props.feed.paymentMethod?.type == "APPLE_PAY") {
					payAppleBehavior?.invoke()
				} else {
					setClosing(true)
					setError(false)
					closeTab(codeProps["code"], tipValue, null, tabPagedeviceData,
						{
							setError(true)
							setClosing(false)
							error(
								ActionMessage("close_tab", "Closing tab with tip = $tipValue , $calculateTotal FAILED")
							)
						},
						{ history("${locationBase(codeProps)}/overdue") },
						{
							log(
								ActionMessage("close_tab", "Closing tab with tip = $tipValue , $calculateTotal")
							)
							pushToComplete(history, codeProps)
						})
				}
			}

		}
		location {
			showBack = false
		}
		div() {
			className = "section"
			div() {
				className = "container text-center"
				p() {
					className = "huge text-white no-top-bottom"
					+"${props.feed.tab?.tabNumber}"
				}
				p() {
					className = "no-bottom"
					+"Tab Number"
				}
			}
		}
		div() {
			className = "section no-top"
			container {
				orderedItems {

					items = props.feed.tab?.items ?: arrayOf()

				}
			}
		}
		div() {
			className = "section no-top"
			container {
				h3() {
					className = "no-top"
					+"Select Tip Amount"
				}
				tip {

					this.subtotal = props.feed.totals?.subTotal ?: "0.00"
					this.tips =
						tips.tips.associate { it.percentage to tipRate(props.feed.totals?.subTotal, it.percentage) }


					this.chosenTip = tipValue
					this.onTipChange = { tipNumber, value ->
						setTipValue(tipNumber to value)
					}

				}
			}
		}
		val totals = props.feed.totals
		if (isPaytronix != null) {
			taoDiscount {

				subtotal = props.feed.totals?.subTotal
				discount = props.feed.totals?.discount
				show = (isPaytronix != null)

			}
		}
		div() {
			className = "section less no-top"
			Receipt {

				this.tip = if (tipValue.first in setOf(18, 20, 22)) tipRate(
					sum(props.feed.totals?.subTotal,props.feed.totals?.discount),
					tipValue.first
				) else tipValue.second
				this.taxesFees = sum(
					sum(
						totals?.tax,
						totals?.fee
					),
					totals?.serviceCharges
				)
				this.serviceCharges = totals?.serviceCharges
				this.credit = totals?.credit
				this.tax = totals?.tax ?: "0.00"
				this.fees = totals?.fee ?: "0.00"
				this.subtotal = totals?.subTotal ?: "0.00"
				this.discount = totals?.discount ?: "0.00"

			}
		}
		div() {
			className = "section no-top"
			container {
				choosePayment {

					this.payment = props.feed.paymentMethod!!

				}
			}
		}


		div() {
			className = "section less bar"
			container {
				div() {
					className = "row clearfix"
					div() {
						className = "column"
						p() {
							className = "text-white font-weight-600 no-top-bottom"
							+"Total:"
						}
					}
					div() {
						className = "column text-right"
						p() {
							className = "text-white font-weight-600 no-top-bottom"
							id = "tab-total"
							+"\$${calculateTotal ?: "Calculating..."}"
						}
					}
				}
			}
		}
	}
	div {
		className = "section less no-bottom"


		css {
			display = if (error) block else none
		}
		container {
			div() {
				className = "message error"
				div() {
					className = "message-wrap"
					span() {
						className = "icon"
						svg() {
							className = "svg-icon"
							viewBox = "0 0 24 24"
							path {
								d =
									"M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z"


								fill = "#ec0b19"


							}
						}
					}
					p() {
						className = "small text-white no-top-bottom"
						span() {
							className = "font-weight-600"
							+"Issue Closing Tab."
						}
						+" Please try again."
					}
				}
			}
		}
	}
	div() {
		className = "section no-top-bottom"
		div() {
			className = "container text-center"
			div() {
				className = "section less no-bottom"
				a {
					className = "btn red ${if (closing || modalShow) "disabled" else ""}"

					onClick = {
						it.preventDefault()
						if (props.feed.paymentMethod?.type == "APPLE_PAY" && !canUseApplePay()) {
							setShowApplePayAlert(true)
						} else
							setShowModal(true)
					}

					if (closing) {
						+"Closing Tab..."
					} else {
						+"Close Tab: "
						span {
							id = "tab-total"
							+"\$$calculateTotal"
						}
					}
				}
			}
		}
	}
	div() {
		className = "section no-top-bottom"
		container {
			div() {
				className = "link-box text-center no-bottom"
				p() {
					className = "small text-grey no-top-bottom"
					+"Tabs left open will auto-close with a 20% tip."
				}
			}
		}
	}
	help() {
		isLess = false
	}
	rooamFooter {}

}

private fun pushToComplete(history: NavigateFunction, codeProps: Params) {
	history(
		"${locationBase(codeProps)}/complete",
		(js("{}") as NavigateOptions).apply { state = TabCompleteProps().apply { showFeedback = true } })
}


private fun calculateTotal(props: TabProps, tipValue: Pair<Int, String>) =
	if (props.feed.totals != null) {
		with(props.feed.totals!!) {
			sum(sum(sum(sum(subTotal, tax), fee), serviceCharges), tipValue.second)
		}
	} else tipValue.second
