package components

import components.icons.dollarIcon
import imported.numberformat.numberFormat
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.useState

@JsModule("./styles/tip.css")
@JsNonModule
external val tipStyle: dynamic

external interface TipProps : Props {
	var subtotal: String
	var tips: Map<Int, String>
	var chosenTip: Pair<Int, String>
	var onTipChange: (Int, String) -> Unit
}

val tip = FC<TipProps> { tipProps ->
	val singleWidth = (1000 - tipProps.tips.size) / tipProps.tips.size
	val (active, setTipActive) = useState(tipProps.chosenTip.first)
	val (tip, setCustomTip) = useState(tipProps.chosenTip.first)

	div {
		className = "section no-top-bottom"
		div {
			className = "row clearfix"
			tipProps.tips.forEach {
				singleTip {
					this.width = singleWidth
					this.active = (it.key == active)
					this.onTipChange = tipProps.onTipChange
					this.setTipActive = { tipVal -> setTipActive(tipVal) }
					this.value = it.toPair()
				}
			}
		}
	}
	div {
		className = "section short"
		div {
			className = "divider text-center"
			span {
				+"or custom"
			}
		}
	}
	div {
		className = "section short no-bottom"
		div {
			className = "row clearfix"
			div {
				className = "column full"
				div {
					className = "input-wrap tip"
					span {
						className = "before-input"
						dollarIcon {}
					}
					numberFormat {

						className = "form-control"
						id = "custom-tip"
						placeholder = "Enter Custom Tip Amount"
						thousandSeparator = true
						allowNegative = false
						decimalScale = 2
						onValueChange = {
							if (it.floatValue < 1.0e14) {
								tipProps.onTipChange(-1, it.value)
								setTipActive(-1)
							}
						}

					}
				}
			}
		}
	}
}


