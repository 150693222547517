package components

import react.FC
import react.Props
import react.dom.html.ReactHTML

external interface TextProps : Props{
	var text: String
}
val dividerWithText = FC<TextProps>{
	props ->
	ReactHTML.div {
		className = "divider text-center"
		ReactHTML.span {
			+props.text
		}
	}
}
