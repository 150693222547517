package components.item

import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span

external interface ItemProps : Props {
	var itemTotal: String
	var itemName: String
	var quantity: Int
	var itemPrice: String
}

val item = FC<ItemProps>("item") { props ->
	div {
		className = "padding"
		itemNumberBox {
			quantity = props.quantity
		}
		p {
			className = "font-weight-500 text-white no-top-bottom"
			id = "item-name"
			+props.itemName
		}
		span {
			className = "text-light-grey"
			+"\u0040"
			span {
				id = "item-price"
				+" \$${props.itemPrice}"
			}
			+" each"
		}
		span() {
			className = "total font-weight-500 text-white"
			id = "item-total"
			+"\$${props.itemTotal}"
		}
	}

}


