package imported.braintree

import imported.braintree.apple.*
import imported.braintree.client.BraintreeOptions
import imported.braintree.data.DataCollectorOptions
import imported.braintree.google.GooglePayOptions
import imported.braintree.google.TransactionInfo
import imported.braintree.google.TransactionProperties
import imported.braintree.hosted.BField
import imported.braintree.hosted.BStyles
import imported.braintree.hosted.BraintreeFields
import imported.braintree.hosted.HostedFieldsOptions
import imported.numberformat.Values


fun BraintreeOptions(): BraintreeOptions = js("{}")

fun HostedFieldsOptions(): HostedFieldsOptions = js("{}")
fun BraintreeFields(): BraintreeFields = js("{}")
fun BField(): BField = js("{}")
fun BStyles(): BStyles = js("{}")
fun ValidationRequest(): ValidationRequest = js("{}")
fun ApplePayOptions(): ApplePayOptions = js("{}")
fun GooglePayOptions(): GooglePayOptions = js("{}")
fun TransactionProperties(): TransactionProperties = js("{}")
fun TransactionInfo(): TransactionInfo = js("{}")
fun ApplePayCreateRequest(): ApplePayCreateRequest = js("{}")
fun ApplePayTotal(): ApplePayTotal = js("{}")
fun TokenRequest(): TokenRequest = js("{}")
fun DataCollectorOptions(): DataCollectorOptions = js("{}")


fun Values(): Values = js("{}")

