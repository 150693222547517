package components

import imported.googleanalitycs.gtag
import react.FC
import react.Props
import react.css.css
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.useLocation
import react.router.useParams
import kotlin.js.json

external interface HelpProps : Props {
	var isLess: Boolean?
}

val help = FC<HelpProps> { props ->
	val codeProps = useParams()
	val location = useLocation()
	div() {
		className = "section${if (props.isLess == true) " less" else ""} no-bottom"

		container {
			div {
				className = "section no-top-bottom"
				a {
					href = "https://forms.gle/jqzZSNnEBNcmynsz6"
					target = AnchorTarget._blank
					className = "help-wrap"
					rel = "noreferrer"
					onClick = {
						gtag(
							"event", "help_request", json(
								"location" to codeProps["code"],
								"path" to location.pathname
							)
						)
					}
					span {
						className = "middle-wrap"
						span {
							className = "icon-circle"
							span {
								className = "icon"
								svg {
									className = "svg-icon"
									viewBox = "0 0 8 12.048"
									path {
										d =
											"M14.837,11.346a1.508,1.508,0,1,0,1.508-1.489A1.479,1.479,0,0,0,14.837,11.346ZM20.2,4.224C20.2,2.1,18.713.768,16.326.768a4.008,4.008,0,0,0-4.124,3.8h2.52a1.547,1.547,0,0,1,1.547-1.623,1.349,1.349,0,0,1,1.47,1.413c0,.9-.764,1.489-1.432,2.253A3.339,3.339,0,0,0,15.238,8.9h2.348a1.906,1.906,0,0,1,.687-1.356C19.133,6.61,20.2,5.656,20.2,4.224Z"
										transform = "translate(-12.202 -0.768)"
										css {
											fill = "#ffffff"
										}
									}
								}

							}
						}
						p {
							className = "no-top-bottom"
							+"Need Help?"
						}
					}
				}
			}

		}
	}
}

