package components

import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.header

external interface HeaderProps : Props {
    var siteTitle: String
}

val Header = FC<Props>("Header"){

        header {
            div { }
        }

}
