package components

import org.w3c.dom.events.Event
import react.*
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span

@JsModule("./styles/modal.css")
@JsNonModule
external val modalStyle: dynamic

@JsModule("./images/icon/confirm.png")
@JsNonModule
external val modalConfirmImage: dynamic


external interface ConfirmModalProps : Props {
	var text: String
	var title: String
	var confirmButtonText: String?
	var showIcon: Boolean?
	var booleanShowModal: Boolean?
	var confirmActionPressed: (Event) -> Unit
	var cancelActionPressed: (Event) -> Unit
}

val confirmModal = FC<ConfirmModalProps> { props ->
	val (active, setActive) = useState(false)
	useEffectOnce {
		setActive(true)
	}
	div {
		className = "section full-page modal ${if (active) "active" else ""}"
		div {
			className = "modal-overlay"
		}
		div {
			className = "middle-content"
			container {
				div {
					className = "modal-box"
					div {
						className = "padding-modal text-center"
						if (props.showIcon == true)
							span {
								className = "confirm-icon up-down"
								img {
									alt = "Confirm Tab Icon"
									src = modalConfirmImage
								}
							}
						h2 {
							className = "text-black no-top-bottom"
							+props.title
						}
						p {
							className = "text-grey"
							+props.text
						}
						div {
							className = "link-box no-bottom"
							div {
								className = "row"
								div {
									className = "column"
									div {
										className = "padding"
										a {
											className = "btn outline"
											onClick = { props.cancelActionPressed(it.nativeEvent) }
											+"Cancel"
										}
									}
								}
								div {
									className = "column"
									div {
										className = "padding"
										a {
											className = "btn black"
											onClick = { props.confirmActionPressed(it.nativeEvent) }
											+(props.confirmButtonText ?: "Confirm")
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

fun RBuilder.modalConfirm(handler: ConfirmModalProps.() -> Unit): Unit {
	return child(confirmModal) {
		this.attrs(handler)
	}
}
