package pages

import api.*
import api.storage.StorageKeys
import api.storage.storeRooamHeader
import components.*
import imported.braintree.Values
import imported.numberformat.numberFormat
import imported.sentry.setUser
import io.ktor.client.call.*
import io.ktor.client.features.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.browser.sessionStorage
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.html.FormMethod
import kotlinx.html.js.onClickFunction
import kotlinx.serialization.Serializable
import mainScope
import org.w3c.dom.events.Event
import react.FC
import react.PropsWithChildren
import react.css.css
import react.dom.*
import react.dom.aria.AriaCurrent
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.*
import react.useState
import styled.css
import styled.inlineStyles
import styled.styledDiv
import kotlin.collections.set
import kotlin.js.json

external interface VerifyProps : PropsWithChildren {
	var phoneNumberFormatted: String
}

external interface OTP {
	var code: String
}

val verify = FC<VerifyProps> { props ->
	val location = useParams()
	val (error, setError) = useState(false)
	val (resendState, setResendState) = useState(false)
	val (errorMessage, setErrorMessage) = useState("Code did not match")
	val (loading, setLoading) = useState(false)
	val (codeValues, setCodeValues) = useState(Values().apply { value = "" })
	val useHistory = useNavigate()
	val submit: (MouseEvent<*, *>) -> Unit = {
		it.preventDefault()
		if (!loading) {
			setLoading(true)
			login(
				sessionStorage.getItem(StorageKeys.phone) ?: "CLEANED UP",
				codeValues.value ?: "",
				{ value ->
					setError(true)
					value?.let { setErrorMessage(value) }
					setLoading(false)
				},
				useHistory,
				location
			)
		}
	}
	layout {
		seo {
			titleTemplate = "Verify Your Phone - %s"
		}
		div {
			className = "section no-top-bottom"
			location {
				showMenu = false
			}
			div() {
				className = "section no-bottom"
				container {
					h1() {
						className = "no-top-bottom"
						+"Enter the Code."
					}
					p() {
						className = "no-bottom"
						+"Sent to ${props.phoneNumberFormatted}"
					}
				}
			}
			div() {
				className = "section less no-bottom"
				container {
					form() {
						method = "get"
						action = "/account"
						id = "form-phone-number"
						div {
							className = "section less no-top"
							css {


								display = if (error) csstype.Display.block else csstype.Display.none
							}
							div() {
								className = "message error"
								div() {
									className = "message-wrap"
									span() {
										className = "icon"
										svg() {
											className = "svg-icon"
											viewBox = "0 0 24 24"
											path {
												d =
													"M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z"

												css {
													fill = "#ec0b19"
												}

											}
										}
									}
									p() {
										className = "small text-white no-top-bottom"
										span() {
											className = "font-weight-600"
											+"$errorMessage."
										}
										+" Please try again."
									}
								}
							}
						}
						div {
							className = "row clearfix"
							div() {
								className = "column full"
								numberFormat {
									className = "form-control"
									id = "verify-code"
									placeholder = "Enter Code Here"
									format = "###-###"
									onValueChange = {
										setCodeValues(it)
									}
									value = codeValues?.value
									autoComplete = "one-time-code"

								}
							}
						}
						div() {
							className = "link-box no-bottom"
							button() {
								className = "btn red ${if (loading) "disabled" else ""}"

								onClick = submit

								+if (loading) "Loading..." else "Next"

							}
						}
					}
				}
			}
			div() {
				className = "section no-top-bottom"
				container {
					div() {
						className = "link-box no-bottom text-center"
						p() {
							className = "small text-grey no-top-bottom"
							+"Problems with your auth-code? "
							a() {
								className = "text-link ${if (resendState) "text-green" else ""}"

								onClick = {
									it.preventDefault()

									if (!resendState) {
										resendCode(sessionStorage.getItem(StorageKeys.phone) ?: "CLEANED UP",
											{ setResendState(it) }) {
											setErrorMessage(it ?: "An Error Has Happened")
											setError(true)
										}

									}

								}
								+if (resendState) "Code sent" else "Send a new code"
							}
						}
					}
				}
			}
			help { }
			rooamFooter {}
		}
	}

}


val login = { phone: String,
              code: String,
              setError: (String?) -> Unit,
              history: NavigateFunction,
              locationProps: Params ->
	mainScope.launch {
		val post: HttpResponse =
			client.post(path = "/login", body = Login(number = phone, code = code, placeCode = "37UTRKXHZY")) {
				header("Content-Type", ContentType("application", "json"))
				addSessionHeader()
				expectSuccess = false
			}
		if (HttpStatusCode.OK != post.status) {
			val receive = post.receive<Map<String, Map<String, String>>>()
			val errors = receive["errors"]
			setError(errors?.values?.first())
			return@launch
		}
		post.storeRooamHeader()
		setUser(
			json(
				"username" to phone
			)
		)
		moveToNext(history, locationProps)
	}

}

suspend fun getState(): HttpResponse {
	val get: HttpResponse = client.get<HttpResponse>(path = "/") {
		header("Content-Type", ContentType("application", "json"))
		addSessionHeader()
		expectSuccess = false
	}.also { it.storeRooamHeader() }
	return get
}


suspend fun getTips(): HttpResponse {
	val get: HttpResponse = client.get<HttpResponse>(path = "tab") {
		header("Content-Type", ContentType("application", "json"))
		addSessionHeader()
		expectSuccess = false
	}.also { it.storeRooamHeader() }
	return get
}

suspend fun checkAuth(): HttpResponse {
	val get: HttpResponse = client.get<HttpResponse>(path = "auth") {
		header("Content-Type", ContentType("application", "json"))
		addSessionHeader()
		expectSuccess = false
	}.also { it.storeRooamHeader() }
	return get
}

suspend fun getSingleFeed(): HttpResponse {
	val get: HttpResponse = client.get<HttpResponse>(path = "feed") {
		header("Content-Type", ContentType("application", "json"))
		addSessionStaticHeader()
		expectSuccess = false
	}
	return get
}


@Serializable
data class Tips(val tips: List<Tip>, val defaultTip: Tip)

@Serializable
data class Tip(val percentage: Int)
