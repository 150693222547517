package apple

import api.*
import apple.import.ApplePaySession
import imported.braintree.ApplePayCreateRequest
import imported.braintree.TokenRequest
import imported.braintree.ValidationRequest
import imported.braintree.apple.braintreeApplePay
import kotlinext.js.jso
import kotlinx.browser.window
import kotlinx.coroutines.async
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import mainScope
import suspendedBrainTreeClient

fun canUseApplePay() = if (!window.asDynamic().ApplePaySession) {
	false
} else window.asDynamic().ApplePaySession.canMakePayments()


val applyApplePay = { appleNotAvailable: (Boolean) -> Unit,
                      totalPay: String?,
                      storeName: suspend (String, String) -> Unit,
                      loading: (Boolean) -> Unit,
                      navigation: (String) -> Unit
	->
	mainScope.async {
		if (!window.asDynamic().ApplePaySession) {
			return@async {
				appleNotAvailable(true)
			}
		}
		if (!window.asDynamic().ApplePaySession.canMakePayments()) {
			return@async {
				appleNotAvailable(true)
			}
		}

		val suspendedClient = suspendedBrainTreeClient.await()
		val braintreeApplePay = braintreeApplePay(jso { client = suspendedClient })
		val applePay = braintreeApplePay.await()
		var createPaymentRequest = applePay.createPaymentRequest(ApplePayCreateRequest().apply {
			total = jso {
				this.amount = (totalPay ?: "0.00")
				this.label = "Rooam"
				this.type = if (totalPay != null) "final" else "pending"
			}
			requiredBillingContactFields = arrayOf("name", "postalAddress")
		})

		return@async {

			loading(true)
			val applePaySession = ApplePaySession(3, createPaymentRequest)

			applePaySession.onvalidatemerchant = { event: dynamic ->
				applePay.performValidation(validationRequest = ValidationRequest().apply {
					displayName = "Rooam"
					validationURL = event.validationURL
				}).then { merchantSession -> applePaySession.completeMerchantValidation(merchantSession) }
					.catch { error ->
						loading(false)
						console.error("Error validating merchant:", error)
						error(ActionMessage("apple_pay", "Error validating merchant, $error"))
						applePaySession.abort()
					}
			}
			applePaySession.oncancel = { it: dynamic ->
				log(ActionMessage("apple_pay", "payment cancelled, $it"))
				loading(false)
			}
			applePaySession.onpaymentauthorized = { it: dynamic ->
				mainScope.launch {
					try {
						val deviceData =
							collectDeviceData(suspendedClient)
								.await()
								.deviceData
						val tokenize = applePay.tokenize(TokenRequest().apply {
							this.token = it.payment.token
						}).await()
						storeName(
							it.payment.billingContact.givenName.toString(),
							it.payment.billingContact.familyName.toString()
						)
						if (totalPay == null) {
							if (!postPayment(tokenize.nonce, deviceData, "APPLE_PAY").await()) {
								throw RuntimeException("Could not store payment")
							}
						}
						applePaySession.completePayment(js("ApplePaySession.STATUS_SUCCESS"))
						navigation(tokenize.nonce)
					} catch (e: Throwable) {
						applePaySession.completePayment(js("ApplePaySession.STATUS_FAILURE"))
						loading(false)
					}
				}
			}
			applePaySession.begin()
		}
	}
}
