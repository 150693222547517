package api

import api.storage.storeRooamHeader
import io.ktor.client.call.*
import io.ktor.client.features.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.async
import kotlinx.serialization.Serializable
import mainScope
import react.StateSetter

val getOverdueDetails = { setOverDue: StateSetter<OverdueResponse?>,
                          setValue: (String?) -> Unit,
                          historyPush: () -> Unit
    ->
    mainScope.async {
        val get: HttpResponse = rawOverdueGet()
        val overdueResponse: OverdueResponse = get.receive()
        if (overdueResponse.hasOverduePayment) {
            setValue(overdueResponse.amount)
            setOverDue { previous ->
                if (previous != overdueResponse) {
                    overdueResponse
                } else previous
            }

        } else {
            historyPush()
        }
    }
}

suspend fun rawOverdueGet(): HttpResponse {
    val get: HttpResponse = client.get<HttpResponse>(path = "/overdue") {
        addSessionHeader()
    }.also { it.storeRooamHeader() }
    return get
}

val payOverdue = { nonce: String?,
                   setError: () -> Unit,
                   historyPush: () -> Unit
    ->
    mainScope.async {
        val post: HttpResponse = client.post<HttpResponse>(path = "/overdue", body = PayOverDue(nonce)) {
            addSessionHeader()
            header("Content-Type", ContentType("application", "json"))
            expectSuccess = false
        }.also { it.storeRooamHeader() }
        if (post.status != HttpStatusCode.OK) {
            setError()
        } else {
            historyPush()
        }
    }
}

@Serializable
data class PayOverDue(
    val nonce: String? = null
)

@Serializable
data class OverdueResponse(
    val hasOverduePayment: Boolean,
    val amount: String? = null,
    val paymentMethods: List<Payment> = listOf(),
    val overdueTabs: List<OverdueTab> = listOf()
)

@Serializable
data class OverdueTab(
    val name: String,
    val number: String,
    val subTotal: String,
    val total: String,
    val tax: String,
    val serviceCharges: String? = null,
    val discounts: String? = null,
    val credit: String? = null,
    val tip: String? = null,
    val baseFare: String? = null,
    val items: List<TabItem>
)
