package pages

import api.storage.StorageKeys
import components.*
import csstype.Display
import getCode
import imported.numberformat.Values
import kotlinx.browser.sessionStorage
import locationBase
import react.FC
import react.PropsWithChildren
import react.css.css
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.p
import react.router.useNavigate
import react.router.useParams
import react.useState

external interface LoginPhoneProps : PropsWithChildren

val loginPhone = FC<LoginPhoneProps> {
	val history = useNavigate()
	val location = useParams()
	val (phoneValues, setPhoneValues) = useState<Values?>(null)
	val (isPhoneValid, setPhoneValid) = useState(false)
	val (showErrorMessage, setShowErrorMessage) = useState(false)
	val (errorMessage, setErrorMessage) = useState("Please enter a valid phone number")
	val (loadingMessage, setLoading) = useState(false)
	layout {
		seo {

			titleTemplate = "%s - Pay Your Tab From Your Phone"
		}
		divPage {
			location {
				isLong = true
				showMenu = false
				showBack = false
			}
			div() {
				className = "section no-bottom"
				container {
					h1() {
						className = "no-top-bottom"
						+"What's Your Number?"
					}
					p() {
						className = "no-bottom"
						+"We’ll text a code to verify your phone."
					}
				}
			}
			div() {
				className = "section less"
				container {
					form() {
						action = "${locationBase(location)}/verify"
						method = "get"
						id = "form-phone-number"
						div() {
							className = "row clearfix"
							div() {
								className = "column full"
								phoneInput {

									showError = showErrorMessage
									onValueChange = {
										setPhoneValues(it)
										if (it.value.length == 10) {
											setPhoneValid(true)
										} else {
											setPhoneValid(false)
										}
									}

								}
							}
							div() {
								className = "error-message"
								css {
									this.display = if (showErrorMessage) Display.block else Display.none

								}
								p {
									className = "small text-red no-top-bottom"
									+"$errorMessage."
								}
							}
						}
						div() {
							className = "link-box no-bottom"
							button {
								className = "btn red"
								if (!isPhoneValid or loadingMessage) {
									className += " disabled"
								}
								onClick = {
									it.preventDefault()
									setLoading(true)
									sessionStorage.setItem(
										StorageKeys.formattedPhone,
										phoneValues?.formattedValue ?: ""
									)

									sessionStorage.setItem(
										StorageKeys.phone,
										phoneValues?.value ?: ""
									)
									phoneValues?.value?.let { phoneValue ->
										getCode(phoneValue, history) {
											it?.let { setErrorMessage(it) }
											setPhoneValid(false)
											setShowErrorMessage(true)
											setLoading(false)
										}
									}
								}

								+if (loadingMessage) "Loading..." else "Next"
							}
						}
					}
				}
			}
			help { isLess = true }
			rooamFooter { }
		}
	}
}
