package components.icons

import react.FC
import react.Props
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg

val infoIcon = FC<Props> {
	svg() {
		className="svg-icon"
		xmlns = "http://www.w3.org/2000/svg"
		viewBox = "0 0 24 24"
		path {
			d =
				"M13.25 7c0 .69-.56 1.25-1.25 1.25s-1.25-.56-1.25-1.25.56-1.25 1.25-1.25 1.25.56 1.25 1.25zm10.75 5c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-2 0c0-5.514-4.486-10-10-10s-10 4.486-10 10 4.486 10 10 10 10-4.486 10-10zm-13-2v2h2v6h2v-8h-4z"
			fill= "rgb(85, 87, 89)"
		}
	}

}
