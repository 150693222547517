package components.icons

import react.FC
import react.Props
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg

val successIcon = FC<Props> {
	span {
		className = "icon"
		svg {
			className = "svg-icon"
			viewBox = "0 0 24 24"
			path {
				d =
					"M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
				fill = "#36c299"

			}
		}
	}
}
