package components.icons

import react.FC
import react.Props

import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG
import react.dom.svg.ReactSVG.path

import react.dom.svg.ReactSVG.svg

val googlePayIcon = FC<Props> {
	span {
		className = "brand"
		svg {
			className = "svg-icon"
			viewBox = "0 0 47.74 18.957"
			ReactSVG.path {
				d =
					"M28.238,9.615v5.529H26.5V1.4h4.66a4.24,4.24,0,0,1,3,1.185A4.058,4.058,0,0,1,34.4,8.193h0l-.158.237a4.24,4.24,0,0,1-3,1.185Zm0-6.477V7.956H31.16A2.45,2.45,0,0,0,32.9,7.245a2.36,2.36,0,0,0,.079-3.317,2.223,2.223,0,0,0-1.817-.711Z"
				transform = "translate(-5.608 -0.294)"

				fill = "#040507"


			}
			path {
				d =
					"M41.828,6.5a4.418,4.418,0,0,1,3.08,1.027,3.53,3.53,0,0,1,1.106,2.844v5.766H44.356V14.791h-.079a3.373,3.373,0,0,1-2.923,1.58,4.081,4.081,0,0,1-2.528-.869A2.846,2.846,0,0,1,37.8,13.211a2.919,2.919,0,0,1,1.106-2.291,4.4,4.4,0,0,1,2.923-.869,5.952,5.952,0,0,1,2.607.553V10.21a1.989,1.989,0,0,0-.711-1.58A2.76,2.76,0,0,0,41.986,8a2.889,2.889,0,0,0-2.37,1.264l-1.58-.948A4.248,4.248,0,0,1,41.828,6.5ZM39.538,13.29a1.68,1.68,0,0,0,.553,1.185,2.145,2.145,0,0,0,1.343.474,3.117,3.117,0,0,0,1.975-.79,2.523,2.523,0,0,0,.869-1.9,3.381,3.381,0,0,0-2.291-.632,3.073,3.073,0,0,0-1.817.553A1.33,1.33,0,0,0,39.538,13.29Z"
				transform = "translate(-7.983 -1.363)"


				fill = "#040507"


			}
			path {
				d =
					"M58,6.9,52.154,20.407H50.338l2.212-4.739L48.6,6.9h1.9l2.765,6.714h0L56.025,6.9Z"
				transform = "translate(-10.252 -1.45)"

				fill = "#040507"


			}
			path {
				d =
					"M17.583,9.78a10.7,10.7,0,0,0-.158-1.58H10v3h4.265a3.769,3.769,0,0,1-1.58,2.37v1.975h2.528A7.148,7.148,0,0,0,17.583,9.78Z"
				transform = "translate(-2.141 -1.723)"
				fill = "#4285f4"


			}
			path {
				d =
					"M8.209,18.319a7.47,7.47,0,0,0,5.213-1.9l-2.528-1.975A4.732,4.732,0,0,1,3.786,12H1.1v2.054A8,8,0,0,0,8.209,18.319Z"
				transform = "translate(-0.271 -2.522)"
				fill = "#34a853"


			}
			path {
				d =
					"M3.565,10.555a3.95,3.95,0,0,1,0-3V5.5H.879a8.031,8.031,0,0,0,0,7.109Z"
				transform = "translate(-0.05 -1.156)"


				fill = "#fbbc04"

			}
			path {
				d =
					"M8.23,3.159a4.24,4.24,0,0,1,3,1.185h0l2.212-2.212A7.351,7.351,0,0,0,8.23,0,7.717,7.717,0,0,0,1.2,4.344L3.807,6.4A4.672,4.672,0,0,1,8.23,3.159Z"
				transform = "translate(-0.292)"


				fill = "#ea4335"


			}
		}
	}
}
