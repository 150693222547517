package components.utils

import react.FC
import react.Props
import react.dom.html.ReactHTML.div

val orbit = FC<Props> {
	div {
		className = "orbit-outter"
	}
	div {
		className = "orbit-inner"
	}
}
