package components

import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.main

external interface ChildrenProps : PropsWithChildren {
}

@JsModule("./styles/global.css")
@JsNonModule
external val global: dynamic


val layout = FC<ChildrenProps> { props ->

	main {
		props.children()
	}

}

