package api

import kotlinx.serialization.Serializable

@Serializable
data class Feed(
    val open: Boolean = false,
    val voided: Boolean = false,
    val tab: Tab? = null,
    val totals: Totals? = null,
    val paymentMethod: Payment? = null
)

@Serializable
data class Payment(
    val type: String,
    val label: String,
    val default: Boolean=false,
    val id: String,
    val image: String
)

@Serializable
data class Totals(
    val total: String,
    val tax: String,
    val subTotal: String,
    val fee: String,
    val partialPayment: String,
    val serviceCharges: String,
    val credit: String,
    val discount: String?=null
)

@Serializable
data class Tab(
    val tabNumber: String?,
    val items: Array<TabItem>
)

@Serializable
data class TabItem(
    val name: String,
    val price: String,
    val pricePerUnit: String,
    val quantity: Int
)
