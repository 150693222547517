package components

import kotlinx.html.LI
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import kotlinx.html.role
import react.Props
import react.dom.*
import react.fc
import react.useState
import styled.css
import styled.styledSpan


@JsNonModule
@JsModule("./styles/receipt.css")
external val receiptStyle: dynamic

external interface ReceiptProps : Props {
	var fees: String
	var tip: String
	var tax: String
	var serviceCharges: String?
	var credit: String?
	var subtotal: String
	var discount: String
	var taxesFees: String
}

val Receipt = fc<ReceiptProps>("receipt") { props ->
	val (receiptActive, setReceiptActive) = useState(false)
	div(classes = "section no-top-bottom") {
		container {
			ul(classes = "receipt") {
				if (props.discount != "0.00") {
					li {
						receiptLine("Discount:", "discount", props.discount)
					}
				}
				li {
					receiptLine("Subtotal:", "subtotal", props.subtotal, if (props.discount!="0.00"){
						" (with discounts) "
					} else null)
				}
				li {
					div(classes = "row clearfix") {
						div(classes = "column") {
							p(classes = "medium font-weight-500 no-top-bottom") {
								+"Taxes & Fees"
								a(
									classes = if (receiptActive) "expand-btn active" else "expand-btn"
								) {
									attrs {
										onClickFunction = {
											it.preventDefault()
											setReceiptActive(!receiptActive)
										}
										role = "button"
									}
									span(classes = "icon") {
										svg(classes = "svg-icon") {
											attrs["viewBox"] = "0 0 24 24"
											path(
												d = "M24 12c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12zm-18.005-1.568l1.415-1.414 4.59 4.574 4.579-4.574 1.416 1.414-5.995 5.988-6.005-5.988z"
											) {
												css {
													declarations["fill"] = "rgb(190,190,190)"
												}
											}
										}
									}
								}
							}
						}
						div(classes = "column text-right") {
							p(classes = "medium font-weight-500 no-top-bottom") {
								attrs["id"] = "taxes-fees"
								+"\$${props.taxesFees}"
							}
						}
					}
				}
			}
		}
		div(
			classes = if (receiptActive)
				"receipt-extra-info active"
			else "receipt-extra-info"
		) {
			container {
				ul(classes = "receipt") {
					li {
						receiptLine("Tax:", "tax", props.tax)
					}
					if (props.serviceCharges != null && props.serviceCharges != "0.00") {
						li {
							receiptLine("Service Charges:", "service", props.serviceCharges!!)
						}
					}
					li {
						div("row clearfix") {
							div("column three-four text-left") {
								p("medium font-weight-500 no-top-bottom") {
									+"Fees:"
								}
								p("small explainer text-grey") {
									+"This fee helps cover the convenience costs of mobile payments, additional security to prevent fraud and maintain a high EMV level to stay PCI compliant."
								}
							}
							div("column one-four text-right") {
								p("medium font-weight-500 no-top-bottom") {
									span {
										attrs.id = "fees"
										+"\$${props.fees}"
									}
								}
							}
						}
					}
				}
			}
		}
		container {
			ul(classes = "receipt") {
				if (props.credit != null && props.credit != "0.00") li {
					receiptLine("Credit:", "credit", props.credit!!)
				}
				li {
					receiptLine("Tip:", "tip", props.tip)
				}
			}
		}
	}
}

private fun RDOMBuilder<LI>.receiptLine(title: String, id: String, value: String, greenText: String?=null) {
	div(classes = "row clearfix") {
		div(classes = "column") {
			p(classes = "medium font-weight-500 no-top-bottom") {
				+title
				if (greenText != null) {
					styledSpan {
						css {
							classes += "text-green"
						}
						+greenText
					}
				}
			}

		}
		div(classes = "column text-right") {
			p("medium font-weight-500 no-top-bottom") {
				attrs["id"] = id
				+"\$$value"
			}
		}
	}
}
