package components

import kotlinx.html.*
import kotlinx.html.attributes.StringAttribute
import react.FC
import react.PropsWithChildren
import react.RBuilder
import react.dom.RDOMBuilder
import react.dom.div
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import styled.StyledDOMBuilder
import styled.styledTag

inline fun RBuilder.path(
	d: String,
	block: StyledDOMBuilder<PATH>.() -> Unit
): Unit = styledTag(block) {
	PATH(attributesMapOf("d", d), it)
}

inline fun RBuilder.path(
	d: String,
	transform: String,
	block: StyledDOMBuilder<PATH>.() -> Unit
): Unit = styledTag(block) {
	PATH(attributesMapOf("d", d, "transform", transform), it)
}

@Suppress("unused")
open class PATH(initialAttributes: Map<String, String>, override val consumer: TagConsumer<*>) :
	HTMLTag("path", consumer, initialAttributes, null, false, false),
	HtmlBlockTag {

}

inline fun RBuilder.g(
	transform: String,
	block: StyledDOMBuilder<G>.() -> Unit
): Unit = styledTag(block) {
	G(attributesMapOf("transform", transform), it)
}

@Suppress("unused")
open class G(initialAttributes: Map<String, String>, override val consumer: TagConsumer<*>) :
	HTMLTag("g", consumer, initialAttributes, null, false, false),
	HtmlBlockTag {

}


inline fun RBuilder.container(block: RDOMBuilder<DIV>.() -> Unit) = div(classes = "container", block = block)
inline fun RBuilder.divPage(block: RDOMBuilder<DIV>.() -> Unit) = div(classes = "section no-top-bottom", block = block)
val divPage = FC<PropsWithChildren> { props ->
	div {
		className = "section no-top-bottom"
		props.children()
	}
}


val p500 = FC<PropsWithChildren> { props ->
	p {
		className = "font-weight-500 no-top-bottom"
		props.children()
	}
}
var CommonAttributeGroupFacade.viewBox: String
	get() = StringAttribute()[this, "viewBox"]
	set(newValue) {
		StringAttribute()[this, "viewBox"] = newValue
	}

val container = FC<PropsWithChildren> { props ->
	div {
		className = "container"
		props.children()
	}
}
