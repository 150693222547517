package components


import imported.numberformat.Values
import imported.numberformat.numberFormat
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span


@JsNonModule
@JsModule("./images/flags/united-states.png")
external val usFlag: dynamic

external interface PhoneProps : Props {
	var onValueChange: (Values) -> Unit
	var showError: Boolean?
}

val phoneInput = FC<PhoneProps>("phone") { props ->
	div() {
		className = "input-wrap phone"
		span() {
			className = "before-input"
			span() {
				className = "icon flag"
				img() {
					src = usFlag
					alt = "United States Flag"
				}
			}
		}
		numberFormat {

			className = "form-control ${if (props.showError ?: false) "input-error" else ""}"
			id = "phone-number"
			placeholder = "Phone Number"
			format = "+1  (###) ###-####"
			onValueChange =
				props.onValueChange

		}
	}
}

