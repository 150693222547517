package pages

import api.HistoryDataWrapper
import api.MessageSendState
import api.receipt
import api.sendReceipt
import components.*
import components.icons.successIcon
import components.summary.Summary
import components.summary.SummaryData
import components.summary.SummaryState
import components.utils.orbit
import csstype.Display.Companion.block
import csstype.Display.Companion.none
import imported.googleanalitycs.gtag
import imported.sentry.getCurrentHub
import io.ktor.http.HttpStatusCode.Companion.Conflict
import io.ktor.http.HttpStatusCode.Companion.NotFound
import io.ktor.http.HttpStatusCode.Companion.OK
import io.ktor.http.HttpStatusCode.Companion.PaymentRequired
import io.ktor.http.HttpStatusCode.Companion.Unauthorized
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.async
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.collectLatest
import locationBase
import mainScope
import react.*
import react.css.css
import react.dom.html.AnchorTarget
import react.dom.html.InputType
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.useNavigate
import react.router.useParams
import kotlin.js.json


@JsModule("./styles/feedback.css")
@JsNonModule
external val feedbackStyle: dynamic

external interface TabCompleteProps : Props {
	var showFeedback: Boolean?
}

fun TabCompleteProps(): TabCompleteProps = js("{}")


val TabComplete = FC<TabCompleteProps> { props ->
	val history = useNavigate()
	val codeProps = useParams()
	val (email, setEmail) = useState("")
	val (emailSend, setEmailSend) = useState(MessageSendState.NONE)
	var summaryProps by useState(SummaryData(SummaryState.LOADING))
	val isPaytronix = sessionStorage.getItem("taoEnabled")

	useLayoutEffectOnce {
		window.scrollTo(0.0, 0.0)
	}
	useEffectOnce {
		val pullJob = mainScope.async {
			val span = getCurrentHub().getScope().getTransaction().startChild(
				json(
					"op" to "pullReceipt",
					"description" to "Pulls latest receipt data"
				)
			)
			try {
				receipt()
					.collectLatest {
						val historyDataWrapper: HistoryDataWrapper = it

						when (historyDataWrapper.status) {
							OK -> summaryProps = SummaryData(SummaryState.SUCCESS, historyDataWrapper.historyData)
							Conflict -> summaryProps = SummaryData(SummaryState.LOADING)
							NotFound -> summaryProps = SummaryData(SummaryState.ERROR)
							PaymentRequired -> history("../overdue")
							Unauthorized -> history("../phone")
							else -> summaryProps = SummaryData(SummaryState.CONNECTION_ERROR)
						}
						span.setStatus("ok")
						span.finish()
					}


			} catch (e: CancellationException) {
				span.finish()
				//ignore
			} catch (e: Exception) {
				console.error(e)
				summaryProps = SummaryData(SummaryState.CONNECTION_ERROR)

				span.setStatus("unavailable")
				span.finish()
			}
		}
		this.cleanup {
			pullJob.cancel("Not needed anymore")
		}

	}
	layout {
		seo {
			titleTemplate = "Tab Complete - %s"
		}
		divPage {
			location { showBack = false }
			div() {
				className = "section no-top-bottom"
				Summary {
					summaryData = summaryProps

				}
			}
			div() {
				className = "section largest"
				div() {
					className = "container text-center"
					div() {
						className = "orbit-box green"
						orbit()
						div() {
							className = "icon"
							svg() {
								className = "svg-icon"
								viewBox = "0 0 29.371 22.736"
								path {
									d = "M15.772,29.736,5.541,19.917,8.71,16.654l7.005,6.678L31.679,7l3.233,3.2Z"
									transform = "translate(-5.541 -7)"


									fill = "#ffffff"

								}
							}
						}
					}
					h2() {
						className = "no-top-bottom"
						+"Payment Complete."
					}
				}
			}
			divPage {
				div() {
					className = "container text-center"
					hr { }
					div() {
						className = "section less no-bottom"
						a() {
							className = "btn red"
							onClick = {
								it.preventDefault()
								history("${locationBase(codeProps)}/open")
							}
							+"Open Another Tab"
						}
					}
					if (props.showFeedback == true && isPaytronix == null) {
						div() {
							className = "section less no-bottom"
							div() {
								className = "section no-top-bottom"
								div() {
									className = "box-wrap text-center"
									div() {
										className = "padding"
										h3() {
											className = "no-top"
											+"How was your payment experience?"
										}
										div() {
											className = "row clearfix"
											div() {
												className = "column"
												div() {
													className = "padding"
													a {
														href = "https://forms.gle/D95Q1D4Q76Mtufqe6"
														target = AnchorTarget._blank
														className = "btn"

														rel = "noreferrer"
														onClick = {
															gtag("event", "feedback_bad", json())
														}
														+"Bad"
													}
												}
											}
											div() {
												className = "column"
												div() {
													className = "padding"
													a {
														href = "https://forms.gle/UPvm9zfq7x96F1JcA"
														target = AnchorTarget._blank
														className = "btn"
														rel = "noreferrer"
														onClick = {


															gtag("event", "feedback_great", json())

														}
														+"Great"
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			form {
				id = "form-receipt-send"
				div() {
					className = "section large no-bottom"
					container {
						div {
							className = "section no-top"


							css {
								display = if (emailSend == MessageSendState.NONE) none else block
							}
							if (emailSend == MessageSendState.SUCCESS) {
								div() {
									className = "message success"
									div() {
										className = "message-wrap"
										successIcon()
										p() {
											className = "small text-white no-top-bottom"
											span() {
												className = "font-weight-600"
												+"Email Receipt Sent!"
											}
											+" Thank you. It should arrive shortly."
										}
									}
								}
							}
							if (emailSend == MessageSendState.ERROR) {
								div() {
									className = "message error"
									div() {
										className = "message-wrap"
										span() {
											className = "icon"
											svg() {
												className = "svg-icon"
												viewBox = "0 0 24 24"
												path {
													d =
														"M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z"


													fill = "#ec0b19"
												}
											}
										}
										p() {
											className = "small text-white no-top-bottom"
											span() {
												className = "font-weight-600"
												+"Send Error."
											}
											+" There was an issue sending your receipt. Please try again."
										}
									}
								}
							}
						}
						label() {
							className = "no-top"
							htmlFor = "email-receipt"
							+"Email Receipt? (optional)"
						}
						div() {
							className = "row clearfix"
							div() {
								className = "column full"
								div() {
									className = "input-wrap email"
									input() {
										type = InputType.email
										className = "form-control"
										placeholder = "Email Receipt"
										id = "email-receipt"
										value = email
										onChange = { setEmail(it.target.asDynamic().value.toString()) }
									}
									span() {
										className = "btn-right"
										a() {
											className = "btn no-animation white"

											onClick = {
												it.preventDefault()
												setEmailSend(MessageSendState.NONE)
												sendReceipt(email) {
													setEmailSend(it)
												}

											}

											+"Send"
										}
									}
								}
							}
						}
					}
				}
			}
			rooamFooter { }
		}
	}
}
