package styles


import kotlinx.css.*
import kotlinx.css.properties.*
import styled.StyleSheet

object Toggle : StyleSheet("Toggle", isStatic = true) {
	val wrapper by css {
		display = Display.inlineBlock
		width = LinearDimension("44px")
		height = LinearDimension("26px")
		backgroundColor = Color("#737373")
		top = LinearDimension("50%")
		right = LinearDimension("15px")
		marginTop = LinearDimension("-13px")
		borderRadius = LinearDimension("26px")
		position = Position.absolute
	}
	val toggle by css {
		display = Display.inlineBlock
		width = LinearDimension("22px")
		height = LinearDimension("22px")
		left = LinearDimension("2px")
		top = LinearDimension("50%")
		marginTop = LinearDimension("-11px")
		backgroundColor = Color.white
		borderRadius = LinearDimension("100%")
		position = Position.absolute
		transition(duration = 250.ms)

	}
	val active by css {
		backgroundColor = Color("#3EC18E")
	}
	val toggleActive by css {


		transform {
			translateX(18.px)
		}

	}
}
/*
.rewards-wrap .toggle-wrap {
    display: inline-block;
    width: 44px;
    height: 26px;
    background-color: #737373;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -13px;
    -webkit-border-radius: 26px;
            border-radius: 26px;
}
.rewards-wrap .toggle-wrap .toggle {
    display: inline-block;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 50%;
    left: 2px;
    margin-top: -11px;
    background-color: #ffffff;
    -webkit-border-radius: 100%;
            border-radius: 100%;
}
.rewards-wrap .toggle-wrap.active {
    background-color: #3EC18E;
}
.rewards-wrap .toggle-wrap:focus, .rewards-wrap .toggle-wrap:hover {
    background-color: #535353;
}
.rewards-wrap .toggle-wrap.active:focus, .rewards-wrap .toggle-wrap.active:hover {
    background-color: #3EC18E;
}
.rewards-wrap .toggle-wrap.active .toggle {
    left: auto;
    right: 2px;
}
.rewards-wrap .btn.rewards.active {
    background-color: #3EC18E;
    color: #000000;
}

 */
