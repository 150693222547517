package api.storage

import api.SessionConstants
import io.ktor.client.statement.*
import io.ktor.util.*
import kotlinx.browser.localStorage

object StorageKeys {
    const val formattedPhone = "fPhone"
    const val phone = "phone"
}


fun HttpResponse.storeRooamHeader() {
    val session = (headers as StringValues)[SessionConstants.rooamSessionHeader]
    session?.let {
        if (it.isNotBlank()) {
            localStorage.setItem(
                SessionConstants.rooamSessionHeader,
                it
            )
        }
    }
}