package components

import api.Payment
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul
import react.useEffect
import react.useState

@JsModule("./styles/payments.css")
@JsNonModule
external val paymentStyle: dynamic

external interface PaymentList : Props {
	var payments: List<Payment>
	var selectedPayment: Payment?
	var onPaymentSelect: (Payment) -> Unit
	var showDelete: Boolean?
	var reload: () -> Unit
}

val paymentList = FC<PaymentList>("payments") { props ->
	val (selectedPayment, setPayment) = useState(props.selectedPayment)
	useEffect(props) {
		if (selectedPayment == null) {
			props.payments.forEach {
				if (it.default) setPayment(it)
			}
		}
	}

	div {
		className = "section no-top-bottom"
		ul() {
			className = "payments clearfix"
			props.payments.forEach {
				li {
					paymentSelect {

						allowDelete = props.showDelete
						payment = it
						this.onSelectPayment = { payment ->
							setPayment(payment)
							props.onPaymentSelect(payment)
						}
						this.isPaymentSelected = it == selectedPayment
						this.reloadList = props.reload

					}
				}
			}
		}
	}

}
