import api.*
import api.hooks.useServiceWorker
import api.storage.StorageKeys
import api.storage.storeRooamHeader
import imported.braintree.BraintreeOptions
import imported.braintree.client.braintreeClient
import imported.googleanalitycs.install
import io.ktor.client.call.*
import io.ktor.client.features.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.coroutines.*
import pages.*
import pages.explainer.Explainer
import pages.tab.tab
import react.*
import react.router.*

val mainScope = MainScope()

val basePath = "/venue/:code"
val basePathClear = "/venue/"

external interface CodeProps : Props {
	var code: String
}


fun locationBase(location: CodeProps?) = "$basePathClear${location?.code}"
fun locationBase(location: Params) = "$basePathClear${location["code"]}"
fun locationBase(placeCode: String?, location: CodeProps?) = "$basePathClear${placeCode ?: location?.code}"
fun locationBase(placeCode: String?, location: Params) = "$basePathClear${placeCode ?: location["code"]}"


val app = FC<Props>("WebTab") {
	useServiceWorker()
	val isPaytronix = sessionStorage.getItem("taoEnabled")
	useLayoutEffect(isPaytronix) {
		val root = window.document.documentElement
		if (isPaytronix != null) {
			root?.asDynamic().style.setProperty("---background-color-wrapper", "#242526")
			root?.asDynamic().style.setProperty("---border-radius-standard-6px", "0px")
			root?.asDynamic().style.setProperty("---border-radius-standard-5px", "0px")
			root?.asDynamic().style.setProperty("---border-radius-standard-4px", "0px")
			root?.asDynamic().style.setProperty("---border-radius-standard-3px", "0px")
			root?.asDynamic().style.setProperty("---border-radius-standard-8px", "0px")
			root?.asDynamic().style.setProperty("---border-radius-standard-10px", "0px")
		}
	}

	useEffectOnce {
		install(googleAnalytics)
	}


	Routes {

		Route {

			path = "/venue"


			Route {
				index = true
				element = createElement {
					notExisting {}
				}

			}
			Route {
				this.path = ":code"

				Route {
					index = true
					this.element = slashPage.create()
				}
				Route {
					this.path = "explainer"
					this.element = createElement(Explainer)
				}
				Route {
					this.path = "phone"
					this.element = createElement(loginPhone)
				}
				Route {
					path = "verify"
					element = createElement {
						verify {
							attrs {
								phoneNumberFormatted = sessionStorage.getItem(StorageKeys.formattedPhone) ?: ""
							}
						}
					}
				}
				Route {
					path = "account"
					element = createElement {
						account {}
					}
				}
				Route {
					path = "addpayment"
					element =
						addPayment.create {
								navigate = { it(-1) }
						}

				}
				Route {
					path = "select"
					element =
						selectPayment.create {
							this.navigate = { history, codeprops, _, _ ->
								log(ActionMessage("select_payment", "changed selected payment"))
								history(-1)
							}
							this.showBack = true
							this.showDelete = false
						}
				}

				Route {
					path = "open"

					element =

						selectPayment.create {

							this.showDelete = true
							this.navigate = { history, codeProps, errorSetter, loadingSetter ->
								openTab(codeProps["code"], {
									errorSetter(true)
									loadingSetter(false)
									error(ActionMessage("tab_open", "Checkin failed"))
								},
									{
										history("${locationBase(codeProps)}/overdue")
										log(ActionMessage("tab_open", "Failed due to overdue"))
									}
								) {
									val tips = getTips()
									val receive = tips.receive<Tips>()
									setTips(receive)
									log(ActionMessage("tab_open", "Success"))
									history("${locationBase(codeProps)}/tab")
								}

							}
							this.error = useLocation().asDynamic().state
							this.errorMessageText = "Issue Opening Tab."
							this.showBack = false
						}

				}

				Route {
					path = "help"
					element =
						helpPage.create()
				}
				Route {
					path = "voided"
					element = tabVoided.create()
				}
				Route {
					path = "complete"
					element = TabComplete.create { showFeedback = true }
				}

				Route {
					path = "overduecomplete"
					element = TabComplete.create {

						this.showFeedback = false

					}

				}
				Route {
					path = "tab"
					element =
						tab.create {

							tabNumber = "LOADING"

						}

				}
				Route {
					path = "overdue"
					element = tabOverdue.create {}
				}
			}
		}

		Route {
			path = "/*"
			element = notExisting.create {}
			index = true

		}
	}
}


val getCode = { phoneNumber: String,
                history: NavigateFunction,
                disablePhone: (String?) -> Unit ->
	mainScope.launch {
		val response: HttpResponse =
			client.post(path = "/login/code", body = Phone(phoneNumber)) {
				header("Content-Type", ContentType("application", "json"))
				expectSuccess = false
			}
		if (response.status == HttpStatusCode.BadRequest) {
			val receive = response.receive<Map<String, Map<String, String>>>()
			val errors = receive["errors"]
			disablePhone(errors?.values?.first())
		} else {
			response.storeRooamHeader()
			history("../verify")
		}
	}
}


val suspendedBrainTreeClient by lazy<Deferred<Any>> {

	mainScope.async {
		val any = BraintreeOptions().apply {
			authorization = loadToken().receive<Token>().braintreeToken
		}
		try {
			return@async braintreeClient(any).await()
		} catch (e: Throwable) {
			console.warn("Connecting to braintree failed retrying:", e)
			for (i in 1..10) {
				try {
					delay(500)
					return@async braintreeClient(any).await()
				} catch (ex: Throwable) {
					console.warn("Connecting to braintree failed retry($i):", ex)
				}
			}
			throw CannotProcessPaymentException()
		}
	}
}

class CannotProcessPaymentException : RuntimeException()
