package pages

import api.MessageSendState
import components.layout
import components.location
import components.rooamFooter
import components.seo
import components.utils.orbit
import locationBase
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.p
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg
import react.router.useNavigate
import react.router.useParams
import react.useState

external interface TabVoidedProps : Props

val tabVoided = FC<TabVoidedProps> {
	val location = useParams()
	val history = useNavigate()
	val (voidedReason, setVoidedReason) = useState("")
	val (reasonSend, setReasonSend) = useState(MessageSendState.NONE)

	layout {
		seo {
			titleTemplate = "Tab Voided - %s"

		}
		div() {
			className = "section no-top-bottom"
			location {
				showBack = false
			}
			div() {
				className = "section largest"
				div() {
					className = "container text-center"
					div() {
						className = "orbit-box"
						orbit()
						div() {
							className = "icon"
							svg() {
								className = "svg-icon"
								viewBox = "0 0 24 24"
								path {
									d =
										"M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M4,12c0-4.4,3.6-8,8-8c1.9,0,3.6,0.6,4.9,1.7L5.7,16.9 C4.6,15.6,4,13.9,4,12z M12,20c-1.9,0-3.6-0.6-4.9-1.7L18.3,7.1c1.1,1.3,1.7,3,1.7,4.9C20,16.4,16.4,20,12,20z"

									fill = "#ffffff"


								}
							}
						}
					}
					h2() {
						className="no-top-bottom"
						+"Tab Voided"
					}
					p() {
						className="no-top-bottom"
						+"No payment was made."
					}
				}
			}
			div() {
				className="section no-top-bottom"
				div() {
					className="container text-center"
					hr {}
					div() {
						className="section less no-bottom"
						a() {
							href = "${locationBase(location)}/open"
							className = "btn red"
								onClick = {
									it.preventDefault()
									history("${locationBase(location)}/open")
								}

							+"Open Another Tab"

						}
					}
				}
			}
//			if (1 == 2) {
//				form {
//					attrs.id = "form-tab-voided"
//					div("section large no-bottom") {
//						container {
//							styledDiv {
//								css {
//									classes.add("section")
//									classes.add("no top")
//
//								}
//								inlineStyles {
//									display =
//										if (reasonSend == MessageSendState.NONE) Display.none else Display.block
//								}
//								if (reasonSend == MessageSendState.SUCCESS) {
//									div("message success") {
//										div("message-wrap") {
//											successIcon()
//											p(classes = "small text-white no-top-bottom") {
//												span("font-weight-600") {
//													+"Feedback Sent!"
//												}
//												+" Thank you. We appreciate the feedback."
//											}
//										}
//									}
//								}
//								if (reasonSend == MessageSendState.ERROR) {
//
//									div("message error") {
//										div("message-wrap") {
//											span("icon") {
//												svg("svg-icon") {
//													attrs.viewBox = "0 0 24 24"
//													path(
//														d = "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z"
//													) {
//
//														inlineStyles {
//															declarations["fill"] = "#ec0b19"
//														}
//
//													}
//												}
//											}
//											p(classes = "small text-white no-top-bottom") {
//												span("font-weight-600") {
//													+"Send Error."
//												}
//												+"There was an issue sending your feeback. Please try again."
//											}
//										}
//									}
//								}
//
//							}
//							label(classes = "no-top") {
//								attrs["htmlFor"] = "whats-your-issue"
//								+"Why didn't you order anything?"
//							}
//							div("row clearfix") {
//								div("column full") {
//									textarea(classes = "form-control", rows = "4", cols = "50") {
//										attrs.placeholder = "Write Feedback Here"
//										attrs["type"] = "text"
//										attrs.id = "void-feedback"
//										attrs.value = voidedReason
//										attrs.onChangeFunction =
//											{ setVoidedReason(it.target.asDynamic().value.toString()) }
//									}
//
//								}
//							}
//
//						}
//					}
//					div("section less no-bottom") {
//						container {
//							a(classes = "btn white") {
//								attrs.onClickFunction = {
//									it.preventDefault()
//									setReasonSend(MessageSendState.NONE)
//									postTabVoidedReason(voidedReason) {
//										setReasonSend(it)
//									}
//								}
//								+"Send"
//
//							}
//						}
//					}
//				}
//			}
			rooamFooter { }
		}
	}
}


